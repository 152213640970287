import { FloatingPortal } from "@floating-ui/react";
import { faUser, faBookmark, faFileLines } from "@fortawesome/free-regular-svg-icons";
import {
  faEarth,
  faAward,
  faCog,
  faSignOut,
  faChartPie,
  faUserPlus,
  faVideoCamera,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSubmit, Link } from "@remix-run/react";
import classNames from "classnames";
import React, { useRef, useMemo, useState } from "react";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";
import type { ParsedAccount } from "~/utils/hive";
import AccountManaBars from "./AccountManaBars";
import { FloatingHTMLOverlay } from "./FloatingHTMLOverlay";
import { LazyMotion, m, cubicBezier } from "framer-motion";
import { useAppStore } from "~/store";
const loadFeatures = () => import("~/components/framermotion/features").then(res => res.default);

interface HamburgerMenuProps {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HamburgerMenu({ visibility, setVisibility }: HamburgerMenuProps) {
  const submit = useSubmit();

  const activeAccount = useAppStore(store => store.account.activeAccount);
  const phrase = useAppStore(store => store.account.keys.phrase);
  const menuRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(menuRef, () => setVisibility(false));
  useOnRouteChange(() => setVisibility(false));

  React.useEffect(() => {
    if (visibility) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [visibility]);

  const displayName = useMemo(() => {
    if (!activeAccount?.name) return "";

    return activeAccount?.posting_json_metadata?.profile?.name || activeAccount.name || "";
  }, [activeAccount]);

  const handleAccountLogout = (activeAccount: ParsedAccount) => {
    localStorage.removeItem("activeAccount");
    localStorage.removeItem("access_token");
    // refresh the page to clear the session
    submit(
      { message: "Remove account session.", accountName: activeAccount.name },
      { method: "delete", action: "/login", relative: "path" }
    );
  };

  const [claimed, setClaimed] = useState(false);

  return (
    <div className="relative flex flex-col z-[9001]">
      {claimed && (
        <FloatingPortal>
          <FloatingHTMLOverlay
            onClick={ev => ev.stopPropagation()}
            className="duration-50 backdrop-blur-sm bg-overlay z-[9002] flex justify-center py-[10vh]"
            lockScroll
          >
            <div
              ref={menuRef}
              className="flex flex-col w-fit h-fit pt-8 pb-6 px-12 bg-pri dark:bg-pri-d rounded-xl items-center animate-reveal-form drop-shadow-md"
            >
              <div className="text-5xl flex justify-center items-center text-center w-32 h-32 bg-green-500/10 rounded-full overflow-hidden mb-4">
                <span className="drop-shadow-[0px_0px_16px_rgb(34_197_94_/_15)] rotate-[30deg]">🎁</span>
              </div>
              <span className="text-2xl font-bold w-full text-center mt-2 mb-3">You've claimed your rewards!</span>

              <div className="flex flex-col font-semibold divide-y divide-pri/50 dark:divide-pri-d/50 items-center justify-center w-full">
                <div className="flex flex-row justify-between w-full py-4">
                  <div className="flex flex-row items-center">
                    <div className="flex items-center w-8 h-8 text-lg font-semibold rounded-full text-red-500 ">H</div>
                    <span className="text-base font-semibold pl-2 first-line:">HIVE</span>
                  </div>
                  <div className="font-bold text-lg text-green-500">+{activeAccount?.reward_hive_balance}</div>
                </div>
                <div className="flex flex-row justify-between w-full py-4">
                  <div className="flex flex-row items-center">
                    <div className="flex items-center w-8 h-8 text-lg font-semibold rounded-full text-green-500 ">
                      H
                    </div>
                    <span className="text-base font-semibold pl-2 first-line:">HBD</span>
                  </div>
                  <div className="font-bold text-lg text-green-500">+{activeAccount?.reward_hbd_balance}</div>
                </div>
                <div className="flex flex-row justify-between w-full py-4">
                  <div className="flex flex-row items-center">
                    <div className="flex items-center w-8 h-8 text-lg font-semibold rounded-full text-yellow-500 ">
                      V
                    </div>
                    <span className="text-base font-semibold pl-2 first-line:">VESTS</span>
                  </div>
                  <div className="font-bold text-lg text-green-500">+{activeAccount?.reward_vests_balance}</div>
                </div>
              </div>

              <button
                type="button"
                onClick={() => setClaimed(false)}
                className="active:scale-105 active:saturate-150 flex items-center justify-center py-3 px-5 mt-4 min-w-[140px] rounded-full bg-acc text-center text-pri font-semibold"
              >
                Great!
              </button>
            </div>
          </FloatingHTMLOverlay>
        </FloatingPortal>
      )}
      <button
        type="button"
        className="relative flex flex-col justify-center items-center w-8 h-8 z-[100001]"
        onClick={() => setVisibility(current => !current)}
      >
        <span
          className={classNames(
            "absolute w-6 h-0.5 rounded-sm bg-acc origin-center transition-transform duration-150 delay-150",
            {
              "rotate-0 -translate-y-[150%]": !visibility,
              "rotate-45 translate-y-[0%]": visibility
            }
          )}
        />
        <span
          className={classNames(
            "absolute w-6 h-0.5 rounded-sm bg-acc origin-center transition-transform duration-150 delay-150",
            {
              "rotate-0 translate-y-[150%]": !visibility,
              "-rotate-45 translate-y-[0%]": visibility
            }
          )}
        />
      </button>

      <FloatingPortal>
        <LazyMotion features={loadFeatures}>
          {visibility && (
            <m.div
              initial={{ x: "100%", opacity: 0.6, scale: 0.985 }}
              animate={{ x: 0, opacity: 1, scale: 1 }}
              exit={{ x: "100%", opacity: 0.6, scale: 0.985 }}
              transition={{
                ease: cubicBezier(0.51, 0, 0.69, 1.09),
                duration: 0.3
              }}
              className="fixed inset-0 w-screen h-[100dvh] bg-pri dark:bg-pri-d z-[100003] overflow-y-auto"
              role="dialog"
            >
              <div className="absolute right-4 top-4">
                <button
                  type="button"
                  className="relative flex flex-col items-center justify-center w-8 h-8"
                  onClick={() => setVisibility(false)}
                >
                  <span className="absolute w-6 h-0.5 rounded-sm bg-acc origin-center transition-transform duration-150 delay-150 rotate-45 translate-y-[0%]" />
                  <span className="absolute w-6 h-0.5 rounded-sm bg-acc origin-center transition-transform duration-150 delay-150 -rotate-45 translate-y-[0%]" />
                </button>
              </div>

              <div className="relative flex flex-col min-h-[calc(100%-32px)] p-6 mt-10 gap-y-12">
                {/* profile */}
                {activeAccount?.name && (
                  <div className="flex flex-row flex-nowrap items-center gap-x-8 w-full">
                    <div className="flex flex-col gap-y-4">
                      <div className="relative flex w-16 h-16 bg-gray-100 dark:bg-zinc-800 rounded-full overflow-hidden">
                        <img
                          src={`https://img.inleo.io/u/${activeAccount.name}/avatar/medium`}
                          alt={activeAccount.name}
                        />
                      </div>

                      <div className="flex flex-col pl-0.5">
                        <strong className="text-2xl text-semibold">{displayName}</strong>
                        <span className="text-base font-medium text-gray-500 dark:text-zinc-500">
                          @{activeAccount.name}
                        </span>
                      </div>
                    </div>

                    <AccountManaBars />
                  </div>
                )}

                {/* <ClientOnly>
                  {() =>
                    hasRewardsToClaim && (
                      <div
                        className={classNames(
                          "mb-2 flex flex-row gap-2 items-center text-center justify-center cursor-pointer p-2 rounded-xl bg-blend-hard-right bg-gradient-to-r from-red-500 via-orange-500 to-yellow-500 h-fit w-full transition-all duration-1000 bg-left hover:bg-ultra-left bg-big",
                          { "backdrop-opacity-50": claiming }
                        )}
                        onClick={() => handleClaim()}
                      >
                        <span
                          className={classNames("text-center", {
                            "animate-bounce": !claiming,
                            "animate-spin": claiming
                          })}
                        >
                          🎁
                        </span>
                        <span
                          className={classNames(
                            "w-[100%] opacity-100 duration-150 transition-all h-fit overflow-hidden text-center",
                            { "w-[0%] h-0": claiming }
                          )}
                        >
                          {claiming || "Claim your rewards"}
                        </span>
                      </div>
                    )
                  }
                </ClientOnly> */}

                <Link
                  to="/publish"
                  className="flex justify-center items-center w-full py-2.5 px-6 rounded-full bg-acc text-pri font-medium"
                >
                  Publish
                </Link>

                {/* mobile navigation */}
                <nav role="navigation" className="flex flex-col gap-y-3 pb-[72px]">
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to={`/profile/${activeAccount.name}`}
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faUser} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Profile</span>
                    </Link>
                  )}
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to={`/dashboard`}
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faChartPie} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Dashboard</span>
                      {/* <span className="flex w-fit py-0.5 px-3 rounded-full bg-acc/10 font-semibold text-acc text-xxs animate-pulse">
                        ✨ NEW
                      </span> */}
                    </Link>
                  )}
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to="/bookmarks"
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faBookmark} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Bookmarks</span>
                    </Link>
                  )}
                  <Link
                    prefetch="intent"
                    to="/shorts"
                    className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                  >
                    <div className="flex items-center justify-center shrink-0 w-10">
                      <FontAwesomeIcon icon={faVideoCamera} size="2xl" />
                    </div>

                    <span className="text-xl font-semibold">Shorts</span>
                  </Link>
                  <Link
                    prefetch="intent"
                    to="/communities"
                    className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                  >
                    <div className="flex items-center justify-center shrink-0 w-10">
                      <FontAwesomeIcon icon={faEarth} size="2xl" />
                    </div>

                    <span className="text-xl font-semibold">Communities</span>
                  </Link>
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to={phrase ? "https://dex.inleo.io" : "https://leodex.io"}
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="48" height="40">
                          <polygon
                            fill="#A1A1AA"
                            points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
                          />
                          <polygon
                            fill="#A1A1AA"
                            points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
                          />
                        </svg>
                      </div>

                      <span className="text-xl font-semibold">LeoDex</span>
                    </Link>
                  )}
                  {
                    <Link
                      prefetch="intent"
                      to="/leaderboard"
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faAward} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Leaderboard</span>
                    </Link>
                  }
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to="/lists"
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faFileLines} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Lists</span>
                    </Link>
                  )}
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to="/premium"
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="48" height="40">
                          <polygon
                            fill="currentColor"
                            points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
                          />
                          <polygon
                            fill="currentColor"
                            points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
                          />
                        </svg>
                      </div>

                      <span className="text-xl font-semibold">Premium</span>
                    </Link>
                  )}
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to={`/referral`}
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faUserPlus} size="xl" />
                      </div>

                      <span className="text-xl font-semibold">Referral</span>
                      {/* <span className="flex w-fit py-0.5 px-3 rounded-full bg-acc/10 font-semibold text-acc text-xxs animate-pulse">
                        ✨ NEW
                      </span> */}
                    </Link>
                  )}
                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to={`/${activeAccount.name}/wallet`}
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faWallet} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Wallet</span>
                    </Link>
                  )}

                  {activeAccount?.name && (
                    <Link
                      prefetch="intent"
                      to="/settings"
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faCog} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Settings</span>
                    </Link>
                  )}
                  {activeAccount?.name && (
                    <button
                      className="flex items-center w-full h-[39px] gap-x-5 py-2.5 text-gray-700 dark:text-zinc-300"
                      onClick={() => {
                        handleAccountLogout(activeAccount);
                      }}
                    >
                      <div className="flex items-center justify-center shrink-0 w-10">
                        <FontAwesomeIcon icon={faSignOut} size="2xl" />
                      </div>

                      <span className="text-xl font-semibold">Sign out</span>
                    </button>
                  )}
                </nav>
              </div>
            </m.div>
          )}
        </LazyMotion>
      </FloatingPortal>
    </div>
  );
}
