import FeaturedTags from "~/components/threads/FeaturedTags";
import React, { useEffect, useMemo, useState } from "react";
import { Link, NavLink, Outlet, useLoaderData, useLocation, useNavigate, useSearchParams } from "@remix-run/react";
import type { LoaderFunction } from "@remix-run/node";
import { json } from "@remix-run/node/dist";
import type { FeaturedThreadTags, PossibleThreadContent, ThreadIndexed, ThreadSort } from "~/utils/thread";
import type { ParsedAccounts } from "~/utils/hive";
import type { TokenPrices } from "~/utils/coingecko";
import { SearchBarInitiator } from "~/components/SearchBarInitiator";
import InfoHeader from "~/components/InfoHeader";
import { SmallAvatar16 } from "~/components/format/SmallAvatar";
import { ClientOnly } from "remix-utils/client-only";
import { leocache } from "~/utils/leocache";
import type { Thread, CachedDiscussion, CachedList } from "~/utils/leocache";
import { isSSR } from "~/utils/ssr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faCheck, faChevronDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Popover from "~/components/Popover";
import useOnClickOutside from "~/hooks/useClickOutside";
import { getInfraSettings } from "~/utils/infra";
import { getActiveAccount } from "~/session.server";
import NewToLeoFinance from "~/components/NewToLeoFinance";
import { useAppStore } from "~/store";
import ListsModal from "~/components/ListsModal";
import { cn } from "~/utils/cn";
import ThreadQuickView from "~/components/threads/QuickView";

export const loader: LoaderFunction = async ({ request, params }) => {
  const activeAccount = await getActiveAccount(request);

  let feed = params.feed || "latest";

  const [threadcasts, tags, settings] = await Promise.all([
    leocache.getLatestThreadcasts() || [],
    leocache.trendingTags,
    activeAccount ? getInfraSettings(activeAccount) : Promise.resolve(null)
  ]);

  if (activeAccount && !params.feed) {
    if (settings && settings.settings?.default_feed !== "latest") {
      feed = settings.settings.default_feed;
    }
  }

  return json({
    feed,
    threadcasts: (threadcasts || []).filter((t: Thread) => t.children >= 15),
    tags,
    activeAccount
  });
};

interface ThreadsData {
  threadcasts: CachedDiscussion[];
  tags: FeaturedThreadTags;
  tokenPrices: TokenPrices;
  scotDenom: number;
  activeAccount: string;
  feed: string;
}

export default function Threads() {
  const { threadcasts, tags, activeAccount, feed: initialSorting } = useLoaderData() as ThreadsData;

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const activeSorting =
    location.pathname === "/threads/v2"
      ? initialSorting
      : location.pathname.split("/threads/v2/")?.[1] || initialSorting;

  return (
    <React.Fragment>
      <main
        id="threads"
        className="w-full flex-1 pc:flex-none pc:w-8/12 border-0 tbl:border-x border-pri dark:border-pri-d shrink-0"
      >
        <ThreadcastList threadcasts={threadcasts} />

        <InfoHeader className="top-0 !backdrop-blur-none !z-1000">
          <ThreadsSortingHeader activeSorting={activeSorting} isLoggedIn={!!activeAccount} />
        </InfoHeader>
        <Outlet />
      </main>

      <aside className="hidden tbl:block w-full max-w-[20rem]">
        {activeAccount ? (
          <div className="sticky top-0 mt-[48px] sm:mt-0 p-3">
            <SearchBarInitiator />
            <SubscribeToPremium />
            <FeaturedTags tags={tags} />
          </div>
        ) : (
          <div className="sticky top-3 mt-[48px] sm:mt-0">
            <NewToLeoFinance />
          </div>
        )}
      </aside>

      {searchParams.get("view") ? <ThreadQuickView /> : null}
      <React.Suspense fallback={<></>}>
        <ListsModal />
      </React.Suspense>
    </React.Fragment>
  );
}

export function SubscribeToPremium() {
  const activeAccount = useAppStore(store => store.account.activeAccount);

  if (!activeAccount?.premium?.is_premium) {
    return (
      <ClientOnly>
        {() => (
          <div className="relative flex flex-col p-4 mt-3 gap-6 border border-pri dark:border-pri-d bg-pri dark:bg-pri-d rounded-xl overflow-hidden">
            <span className="absolute -top-[60px] inset-x-0 w-full min-h-[160px] bg-[radial-gradient(56.1514%_56.1514%_at_49.972%_38.959%,_rgb(222,171,115)_0%,_rgb(0,0,0,0)_100%)] dark:bg-[radial-gradient(56.1514%_56.1514%_at_49.972%_38.959%,_rgb(73,57,39)_0%,_rgb(0,0,0,0)_100%)] z-0" />

            <div className="relative flex flex-col z-10 gap-6">
              <div className="flex flex-col gap-2">
                <h1 className="text-lg drop-shadow">Subscribe to Premium</h1>
                <p className="text-sm drop-shadow text-gray-800 dark:text-gray-300 leading-snug">
                  Subscribe to unlock exclusive features and rewards.
                </p>
              </div>

              <Link prefetch="intent" to="/premium" className="flex flex-1">
                <button className="flex flex-1 justify-center items-center px-4 py-2 text-sm font-semibold bg-pri-d dark:bg-pri text-pri-d dark:text-pri rounded-full transition-colors hover:bg-pri-hov-d dark:hover:bg-pri-hov">
                  Subscribe
                </button>
              </Link>
            </div>
          </div>
        )}
      </ClientOnly>
    );
  }

  return null;
}

export interface FetchIndex {
  (index: number, sort: ThreadSort): Promise<ThreadIndexed[]>;
}

export interface V2ThreadsLoader {
  feed: string;
  tag?: string;
  threads?: Thread[];
  nextPage?: string;
  session?: string;
  activeAccount?: string | null;
}

export interface ThreadsLoader {
  threadContents: PossibleThreadContent[];
  accounts: ParsedAccounts;
  threadIndices: CachedDiscussion[];
  tagName?: string;
  ignoredUsers?: string[];
}

export interface ThreadsSession {
  index: number;
  threads?: PossibleThreadContent[];
}

interface ThreadsSortingHeader {
  activeSorting: string;
  isLoggedIn: boolean;
}

const ThreadsSortingHeader = React.memo(({ activeSorting, isLoggedIn }: ThreadsSortingHeader) => {
  const navigate = useNavigate();
  const location = useLocation();

  let { activeAccount, keys, premium: premiumState } = useAppStore(store => store.account);

  if (!keys) {
    //null or undefined case for #343 (not sure, it's a powerful trial)
    keys = {
      signature: "",
      publicKey: "",
      proxy: null
    };
  }

  const { signature, publicKey, proxy } = keys;

  const [signatureFromStorage] = useState(!isSSR() && window.localStorage.getItem("activeAccount"));

  const [lists, setLists] = React.useState<CachedList[] | null>(null);
  const [listsDropdown, setListsDropdown] = React.useState<boolean>(false);
  const listsDropdownRef = React.useRef<HTMLDivElement>(null);

  const ableToUseListFeed = activeAccount && (signature || signatureFromStorage) && premiumState.is_premium;

  useEffect(() => {
    //  if (lists !== null) return;

    if (!activeAccount || (!signature && !signatureFromStorage) || !publicKey || !premiumState.is_premium) {
      setLists([]);
      return;
    }

    void (async function () {
      const lists = await leocache.getAuthorLists(
        activeAccount?.name,
        publicKey,
        signature || signatureFromStorage,
        proxy === "hivesigner"
      );

      if (!lists) return;

      setLists(lists);
    })();
  }, [activeAccount, premiumState, location, proxy, publicKey, signature, signatureFromStorage]);

  const feeds = isLoggedIn ? ["foryou", "latest", "trending", "following"] : ["latest", "trending"];

  const currentFeed = React.useMemo(() => {
    return location.pathname.split("/threads/")[1];
  }, [location]);

  const list_feed = React.useMemo(() => {
    if (!currentFeed) return undefined;

    return currentFeed?.includes(":")
      ? lists?.find(l => l.account === currentFeed.split(":")[0] && l.slug === currentFeed.split(":")[1])
      : undefined;
  }, [lists, currentFeed]);

  useOnClickOutside(listsDropdownRef, () => setListsDropdown(false));

  return (
    <div className="flex flex-col h-fit text-pri dark:text-pri-d font-bold w-full !z-1000">
      <div className="flex flex-row w-full text-center select-none">
        {feeds.map(sortingName => (
          <ThreadsSortingHeaderItem key={sortingName} activeSorting={activeSorting} sortingName={sortingName} />
        ))}

        {/* custom list feed */}
        {ableToUseListFeed ? (
          lists === null ? (
            <div
              className={cn(
                `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
              h-14 text-sm opacity-50 cursor-not-allowed`
              )}
            >
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
            </div>
          ) : lists?.length > 0 ? (
            <div ref={listsDropdownRef} className="relative flex flex-1 items-center justify-center">
              <button
                type="button"
                className="flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] h-14 text-sm font-semibold cursor-pointer capitalize transition-colors"
                onClick={() => {
                  setListsDropdown(current => !current);
                }}
              >
                {list_feed ? (
                  <div
                    className={cn(
                      `flex flex-1 gap-x-2 items-center justify-center content-center
                        px-1 h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`,
                      {
                        "text-pri dark:text-pri-d": `${activeSorting}-list` === `${list_feed.account}:${list_feed.slug}`
                      }
                    )}
                  >
                    <div className="relative flex w-5 h-5 rounded-full overflow-hidden">
                      <img src={list_feed.image} alt="" className="w-full h-full object-cover" />
                    </div>
                    <span className="inline whitespace-nowrap max-w-[44px] sm:max-w-[94px] text-ellipsis overflow-hidden">
                      {list_feed.name}
                    </span>

                    <FontAwesomeIcon icon={faChevronDown} size="xs" fixedWidth />
                  </div>
                ) : lists?.length > 0 ? (
                  <div
                    className={cn(
                      `flex flex-1 gap-x-2 items-center justify-center content-center
                        px-1 h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`
                    )}
                  >
                    <span className="inline whitespace-nowrap max-w-[90px] text-ellipsis overflow-hidden">
                      Your Lists
                    </span>

                    <FontAwesomeIcon icon={faChevronDown} size="xs" fixedWidth />
                  </div>
                ) : (
                  <Link
                    id="add-feed-tooltip"
                    to="/lists"
                    className={cn(
                      `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
                        h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`
                    )}
                  >
                    <FontAwesomeIcon icon={faAdd} fixedWidth />
                    <Popover anchorId={`add-feed-tooltip`} content="Add feed" place="bottom" />
                  </Link>
                )}
              </button>

              {listsDropdown ? (
                <div className="absolute top-full right-0 w-full min-w-[180px] sm:min-w-[240px] mt-0.5 rounded-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d overflow-hidden z-1000">
                  <div className="flex flex-1 flex-col max-h-[250px] overflow-y-auto divide-y divide-pri/50 dark:divide-pri-d/50">
                    {lists.map((list, index) => (
                      <button
                        key={index}
                        type="button"
                        className={cn(
                          "flex flex-1 items-center px-3.5 py-3 gap-x-2 text-sm font-medium hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150",
                          {
                            "bg-pri-d/[.05] dark:bg-pri/[.05]": list.slug === list_feed?.slug
                          }
                        )}
                        onClick={() => {
                          navigate(`/threads/${list.account}:${list.slug}`);

                          setTimeout(() => {
                            setListsDropdown(false);
                          }, 50);
                        }}
                      >
                        {list.image ? (
                          <div className="flex w-5 h-5 rounded-full bg-gray-200 dark:bg-zinc-700 overflow-hidden">
                            <img src={list.image} alt="" className="w-5 h-5 object-cover" />
                          </div>
                        ) : null}

                        <span className="flex flex-1 pr-2 text-left">{list.name}</span>

                        {list.slug === list_feed?.slug && (
                          <span className="flex justify-center items-center w-4 h-4 ml-auto">
                            <FontAwesomeIcon icon={faCheck} size="sm" fixedWidth />
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <Link
              id="add-feed-tooltip"
              to="/lists"
              className={cn(
                `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
                  h-14 text-sm text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d font-semibold cursor-pointer capitalize transition-colors`
              )}
            >
              <FontAwesomeIcon icon={faAdd} fixedWidth />
              <Popover anchorId={`add-feed-tooltip`} content="Add feed" place="bottom" />
            </Link>
          )
        ) : null}
        {/* // ) : list_feed ? (
      //   <Link
      //     to={`/threads/${list_feed.account}:${list_feed.slug}`}
      //     className={classNames(
      //       `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
      //     hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] h-14 text-sm font-semibold cursor-pointer capitalize transition-colors`,
      //       {
      //         "border-b-2 border-acc":
      //           activeSorting === `${list_feed.account}:${list_feed.slug}`
      //       }
      //     )}
      //   >
      //     <div className="relative flex w-6 h-6 rounded-full overflow-hidden">
      //       <img
      //         src={list_feed.image}
      //         alt=""
      //         className="w-full h-full object-cover"
      //       />
      //     </div>
      //     <span className="inline whitespace-nowrap max-w-[90px] text-ellipsis overflow-hidden">
      //       {list_feed.name}
      //     </span>
      //   </Link>
      // ) : activeAccount ? (
      //   <Link
      //     id="add-feed-tooltip"
      //     to="/settings#feed"
      //     className={classNames(
      //       `flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
      //       hover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] h-14 text-sm font-semibold cursor-pointer capitalize transition-colors`
      //     )}
      //   >
      //     <FontAwesomeIcon icon={faAdd} fixedWidth />
      //     <Popover
      //       anchorId={`add-feed-tooltip`}
      //       content="Add feed"
      //       place="bottom"
      //     />
      //   </Link>
      // ) : null} */}
      </div>
    </div>
  );
});
ThreadsSortingHeader.displayName = "ThreadsSortingHeader";

interface ThreadsSortingItem {
  activeSorting: string;
  sortingName: string;
}

function ThreadsSortingHeaderItem({ sortingName, activeSorting }: ThreadsSortingItem) {
  // const clearCache = useAppStore(store => store.threads.clearCache);

  // function clearStoreCache() {
  //   clearCache();
  // }

  return (
    <NavLink
      to={`/threads/v2/${sortingName}`}
      prefetch="render"
      className={({ isActive }) =>
        cn(
          "relative flex flex-1 flex-wrap items-center justify-center content-centerhover:bg-pri-d/[.05] dark:hover:bg-pri/[.05] hover:text-pri dark:hover:text-pri-d h-14 text-sm font-semibold cursor-pointer capitalize transition-colors",
          "text-pri/60 dark:text-pri-d/60",
          {
            "text-pri dark:text-pri-d": isActive || activeSorting === sortingName
          }
        )
      }
      end
      // onClick={() => clearStoreCache()}
    >
      {sortingName === "foryou" ? "For You" : sortingName}
    </NavLink>
  );
}

function ThreadcastList({ threadcasts }: { threadcasts: CachedDiscussion[] }) {
  if (!threadcasts || threadcasts?.length === 0) return null;
  return (
    <div className="relative flex flex-1 w-full h-20 sm:h-24 border-b mt-[48px] sm:mt-0 border-pri dark:border-pri-d overflow-hidden">
      <div className="flex flex-1 items-center w-max py-4 pl-4 gap-x-6 sm:gap-x-8 whitespace-nowrap overflow-y-hidden overflow-x-auto snap-mandatory touch-pan-x scrollbar-thin scrollbar-track-transparent scrollbar-thumb-slate-200 dark:scrollbar-thumb-zinc-800 scrollbar-thumb-rounded-full">
        {threadcasts?.map((tc, index) => (
          <ThreadcastIndicator key={index} threadcast={tc} />
        ))}
      </div>
    </div>
  );
}

function ThreadcastIndicator({ threadcast }: { threadcast: CachedDiscussion }) {
  return (
    <div className="relative flex shrink-0 size-12 sm:size-16">
      <SmallAvatar16 author={threadcast.author} threadcast={threadcast} className="size-12 sm:size-16" />
    </div>
  );
}
