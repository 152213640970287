import { useMemo, useState } from "react";

export const HIVE_ENGINE_API = "https://api.hive-engine.com/rpc/contracts";

const HIVE_ENGINE_NODE_LIST = [
  "https://api.hive-engine.com/rpc/contracts",
  "https://api2.hive-engine.com/rpc/contracts",
  "https://herpc.dtools.dev/rpc/contracts",
  "https://ha.herpc.dtools.dev/rpc/contracts",
  "https://engine.rishipanthee.com/rpc/contracts"
];

export interface Balances {
  find(arg0: (token: Metrics) => boolean): Balances;
  _id: number;
  account: string;
  symbol: string;
  balance: string;
  stake: string;
  pendingUnstake: string;
  delegationsIn: string;
  delegationsOut: string;
  pendingUndelegations: string;
}

export interface PendingUnstakes {
  _id: number;
  account: string;
  symbol: string;
  quantity: string;
  quantityLeft: string;
  nextTransactionTimestamp: number;
  numberTransactionsLeft: number;
  millisecPerPeriod: string;
  txID: string;
}

export interface Tokens {
  _id: number;
  issuer: string;
  symbol: string;
  name: string;
  metadata: string;
  precision: number;
  maxSupply: string;
  supply: string;
  circulatingSupply: string;
  stakingEnabled: boolean;
  unstakingCooldown: number;
  delegationEnabled: boolean;
  undelegationCooldown: number;
  numberTransactions?: number;
  totalStaked?: string;
}

export interface Metrics {
  _id: number;
  symbol: string;
  volume: string;
  volumeExpiration: number;
  lastPrice: string;
  lowestAsk: string;
  highestBid: string;
  lastDayPrice: string;
  lastDayPriceExpiration: number;
  priceChangeHive: string;
  priceChangePercent: string;
}

const asRpcRequest = (preparedParams: object) => {
  return {
    jsonrpc: "2.0",
    id: 1,
    method: "find",
    params: preparedParams
  };
};

interface HiveEngineParam {
  account?: string;
  tokens?: string[];
  symbol?: string;
}

export const HIVE_ENGINE_PARAMS = {
  balances: ({ account, symbol }: HiveEngineParam) => {
    const params = {
      contract: "tokens",
      table: "balances",
      query: {
        account: account,
        symbol
      },
      limit: 1000,
      offset: 0,
      indexes: []
    };

    return asRpcRequest(params);
  },
  pending_unstakes: ({ account, tokens }: HiveEngineParam) => {
    const params = {
      contract: "tokens",
      table: "pendingUnstakes",
      query: {
        account: account,
        symbol: {
          $in: [...tokens]
        }
      },
      limit: 1,
      offset: 0,
      indexes: []
    };

    return asRpcRequest(params);
  },
  tokens: ({ tokens }: HiveEngineParam) => {
    const params = {
      contract: "tokens",
      table: "tokens",
      query: {
        symbol: {
          $in: [...tokens]
        }
      },
      limit: 1000,
      offset: 0,
      indexes: []
    };

    return asRpcRequest(params);
  },
  metrics: ({ tokens }: HiveEngineParam) => {
    const params = {
      contract: "market",
      table: "metrics",
      query: {
        symbol: {
          $in: [...tokens]
        }
      },
      limit: 1000,
      offset: 0,
      indexes: []
    };

    return asRpcRequest(params);
  }
};

async function callHiveEngineClient(params: HiveEngineParam) {
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const nodesToTry = HIVE_ENGINE_NODE_LIST;
  const maxRetries = nodesToTry.length;
  let retries = 0;
  let nodeIndex = 0;

  while (retries < maxRetries) {
    const node = nodesToTry[nodeIndex % nodesToTry.length]; // Cycle through nodes
    try {
      const response = await fetch(node, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json"
        }
      });
      if (!response.ok) {
        console.error(`Error fetching ${node}: ${response.statusText}`);
        await delay(100);
      } else {
        return await response.json();
      }
    } catch (error) {
      console.error({
        rpc: node,
        params: params,
        error: `Error fetching ${node}: ${error instanceof Error ? error.message : error}`
      });
      await delay(100);
    }
    retries++;
    nodeIndex++;
  }

  console.error(`All fetch attempts failed after ${maxRetries} retries.`);
  return null;
}

export async function fetchBalance(account: string): Promise<Balances> {
  return await callHiveEngineClient(HIVE_ENGINE_PARAMS.balances(account as any) as HiveEngineParam);
}

export async function fetchTokens(tokens: string[]): Promise<Tokens[]> {
  return await callHiveEngineClient(HIVE_ENGINE_PARAMS.tokens(tokens as any) as HiveEngineParam);
}

export async function fetchPendingUnstakes(account: string, tokens: string[]): Promise<PendingUnstakes> {
  return await callHiveEngineClient(HIVE_ENGINE_PARAMS.pending_unstakes(account, tokens) as HiveEngineParam);
}

export async function fetchMetrics(tokens: string[]): Promise<Metrics[]> {
  return await callHiveEngineClient(HIVE_ENGINE_PARAMS.metrics(tokens as any) as HiveEngineParam);
}

export enum HiveEngineFetchState {
  Initial,
  Failed,
  Fetched
}

interface UseHiveEngine {
  fetchFunction: Function;
  params: {
    tokens?: string[];
    account?: string;
  };
  deps?: Array;
}

export function useHiveEngine({ fetchFunction, params, deps }: UseHiveEngine) {
  const [fetchState, setFetchState] = useState(HiveEngineFetchState.Initial);
  const [hiveEngineContent, setHiveEngineContent] = useState<Balances[] | Tokens[] | PendingUnstakes[] | Metrics[]>();

  if (params.tokens?.length === 0) return [[], HiveEngineFetchState.Failed];
  useMemo(
    () =>
      void (async function () {
        try {
          const { result: hiveEngineContent } = await fetchFunction(params);
          setHiveEngineContent(hiveEngineContent);
          setFetchState(HiveEngineFetchState.Fetched);
        } catch (_) {
          console.error(`Hive engine fetch error: ${_}`);
          setHiveEngineContent([]);
          setFetchState(HiveEngineFetchState.Failed);
        }
      })(),
    [setFetchState, setHiveEngineContent, deps ? deps : null]
  );

  return [hiveEngineContent, fetchState] as const;
}
