import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, Outlet, useLoaderData } from "@remix-run/react";
import { BackButton } from "~/components/InfoHeader";

import WalletSidebar, { WalletSmall } from "~/components/wallet/WalletSidebar";
import { WalletContext } from "~/contexts";
import type { LoaderFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { cache } from "~/utils/cache";
import type { TokenPrices } from "~/utils/coingecko";
import { PriceTicker, PriceTickerWrapper } from "~/components/wallet/PriceTicker";
import type { Balances, Metrics, PendingUnstakes } from "~/utils/hiveengine";
import {
  fetchBalance,
  fetchMetrics,
  fetchPendingUnstakes,
  HiveEngineFetchState,
  useHiveEngine
} from "~/utils/hiveengine";
import type { ParsedAccount } from "~/utils/hive";
import { fetchAccount, fetchAccountHistory } from "~/utils/hive";
import { SearchBarInitiator } from "~/components/SearchBarInitiator";
import { useAppStore } from "~/store";
import { useMediaQuery } from "~/hooks/useMediaQuery";
import { SmallAvatar12, SmallAvatar14, SmallAvatar6 } from "~/components/format/SmallAvatar";
import DisplayName from "~/components/format/DisplayName";
import AccountName from "~/components/format/AccountName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

export const loader: LoaderFunction = async ({ params }) => {
  const accountName = params?.account?.replaceAll("@", "");

  const [leoToken, tokenPrices, account, dynamicGlobalProperties, hiveAccountHistory] = await Promise.all([
    cache.getScotToken("LEO"),
    cache.getTokenPrices(),
    fetchAccount(accountName as string),
    cache.getDynamicGlobalProperties(),
    accountName ? fetchAccountHistory(accountName, -1, 1000) : null
  ]);

  return json({
    leoToken,
    tokenPrices,
    account,
    dynamicGlobalProperties,
    hiveAccountHistory
  });
};

export const parseEngineTokenPrice = (metrics: Metrics, tokenName: string, hivePrice: object) => {
  if (!metrics) return { priceChangePercent: `0%`, lastPrice: 0 };
  const token: Metrics = metrics?.find((token: Metrics) => token.symbol === tokenName) ?? {
    priceChangePercent: `0%`,
    lastPrice: 0
  };
  return {
    usd: (hivePrice?.usd || 0) * Number(token.lastPrice),
    usd_24h_change: Number(token.priceChangePercent.slice(0, -1))
  };
};

export const parseEngineTokenBalance = (balance: Balances, tokenName: string): Balances => {
  return balance?.find((token: Metrics) => token.symbol === tokenName) ?? undefined;
};

export const parseEngineTokenUnstakes = (pendingUnstakes: PendingUnstakes[], tokenName: string): PendingUnstakes => {
  return (
    pendingUnstakes?.find((token: PendingUnstakes) => token.symbol === tokenName) ??
    ({ quantity: 0, quantityLeft: 0 } as unknown as PendingUnstakes)
  );
};

export interface WalletContextTypes {
  metrics: Metrics;
  balance: Balances;
  hivePrice: object;
  pendingUnstakes: PendingUnstakes;
  account: ParsedAccount;
  accountName: string;
  dynamicGlobalProperties: any;
  hiveAccountHistory: object;
}

interface WalletData {
  tokenPrices: TokenPrices;
  scotDenom: number;
  leoToken: object; // todo object
  account: ParsedAccount;
  dynamicGlobalProperties: any;
  hiveAccountHistory: object;
}

export default function Wallet() {
  const { account, tokenPrices, dynamicGlobalProperties, hiveAccountHistory } = useLoaderData() as WalletData;

  const userProfileContainer = useRef<HTMLDivElement>(null);

  const [tokens, setTokens] = useState<string[] | undefined>(undefined);
  const [showHeaderLabel, setShowHeaderLabel] = useState(false);

  const color = useAppStore(store => store.settings.color);
  const setTokenPrices = useAppStore(store => store.wallet.setTokenPrices);

  useEffect(() => {
    setTokenPrices(tokenPrices);
  }, [tokenPrices, setTokenPrices]);

  const { hive_dollar: hbdPrice, hive: hivePrice, dash: dashPrice, splinterlands: spsPrice } = tokenPrices;

  const [balance, balanceFetchState] = useHiveEngine({
    fetchFunction: fetchBalance,
    params: {
      account: account?.name || ""
    }
  });

  const [metrics] = useHiveEngine({
    fetchFunction: fetchMetrics,
    params: {
      tokens: tokens
    },
    deps: tokens
  });

  const [pendingUnstakes] = useHiveEngine({
    fetchFunction: fetchPendingUnstakes,
    params: {
      tokens: tokens,
      account: account?.name || ""
    },
    deps: tokens
  });

  const leoPrice = parseEngineTokenPrice(metrics, "LEO", hivePrice);

  useEffect(() => {
    if (typeof balance !== "object") return;

    const symbolsList = balance.length > 0 ? balance.map((token: object) => token.symbol) : undefined;

    if (balanceFetchState === HiveEngineFetchState.Fetched) {
      setTokens(symbolsList);
    }
  }, [balance, balanceFetchState, setTokens]);

  useEffect(() => {
    if (typeof document === "undefined") return;

    function handleScroll() {
      if (userProfileContainer.current === null) {
        setShowHeaderLabel(window.scrollY > 100);
        return;
      }

      const top = userProfileContainer.current?.getBoundingClientRect().top;
      setShowHeaderLabel(top <= 0);
    }

    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  const walletContext = {
    metrics: metrics || [],
    balance,
    hivePrice,
    pendingUnstakes: pendingUnstakes || [],
    account,
    accountName: account?.name || "",
    dynamicGlobalProperties,
    hiveAccountHistory
  };

  const isMobile = useMediaQuery("(max-width: 992px)");

  const tickers = useMemo(
    () => [
      { index: 0, token: "LEO", price: leoPrice?.usd || 0, change: leoPrice?.usd_24h_change },
      { index: color === "Dash" ? 1 : 4, token: "DASH", price: dashPrice?.usd || 0, change: dashPrice?.usd_24h_change },
      { index: color === "Sps" ? 1 : 5, token: "SPS", price: spsPrice?.usd || 0, change: spsPrice?.usd_24h_change },
      { index: 2, token: "HIVE", price: hivePrice?.usd || 0, change: hivePrice?.usd_24h_change },
      { index: 3, token: "HBD", price: hbdPrice?.usd || 0, change: hbdPrice?.usd_24h_change }
    ],
    [leoPrice, dashPrice, hivePrice, hbdPrice, spsPrice, color]
  );

  const tickers_sorted = useMemo(() => tickers.sort((a, b) => a.index - b.index), [tickers]);

  return (
    <React.Fragment>
      <main className="flex-1 w-full border-0 tbl:border-r border-pri dark:border-pri-d shrink-0 pc:w-8/12">
        <WalletHeader>
          <div className="flex flex-row content-center">
            <BackButton />

            <AnimatePresence>
              {showHeaderLabel ? (
                <motion.div
                  initial={{ opacity: 0, y: 6, filter: "blur(4px)" }}
                  animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
                  exit={{ opacity: 0, y: 6, filter: "blur(4px)" }}
                  transition={{ duration: 0.15 }}
                  className="flex items-center justify-center h-full gap-x-1"
                >
                  <SmallAvatar6 author={account?.name} disableThreadcast disableThreadcastIndicator />
                  <WalletHeaderLabel>{account?.name || ""}`s Wallet</WalletHeaderLabel>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>
        </WalletHeader>

        <div ref={userProfileContainer} className="flex flex-row items-center justify-between gap-x-3 py-5 px-5">
          <div className="flex flex-row items-center gap-x-3">
            <SmallAvatar12 author={account?.name} disableThreadcast disableThreadcastIndicator />
            <div className="flex flex-col gap-y-0.5">
              <DisplayName name={account.name} authorName={account.name} />
              <AccountName author={account.name} />
            </div>
          </div>

          <Link
            to={`/profile/${account.name}`}
            target="_blank"
            rel="noreferrer noopenner"
            role="button"
            className="flex justify-center items-center gap-x-2 py-2 px-4 rounded-full bg-pri-d dark:bg-pri text-pri-d dark:text-pri text-xs font-semibold hover:brightness-75 transition-all duration-150"
          >
            <span>View Profile</span>
            <FontAwesomeIcon icon={faArrowUpLong} size="sm" className="rotate-45" />
          </Link>
        </div>

        {isMobile && (
          <div className="flex flex-row gap-4 justify-between w-full px-4 pt-3 items-center">
            <WalletSmall
              name="LEO"
              description="BLOCKCHAIN WALLET"
              icon="leo"
              path={`/${account?.name || ""}/wallet/leo`}
            />
            <WalletSmall
              name="HIVE"
              description="BLOCKCHAIN WALLET"
              icon="hive"
              path={`/${account?.name || ""}/wallet/hive`}
            />
            <WalletSmall
              name="HIVE ENGINE"
              description="TOKENS"
              icon="hive-engine"
              path={`/${account?.name || ""}/wallet/hive-engine`}
            />
          </div>
        )}

        <WalletBody>
          <WalletContext.Provider value={walletContext}>
            <PriceTickerWrapper>
              {tickers_sorted.map(ticker => (
                <PriceTicker key={ticker.index} token={ticker.token} price={ticker.price} change={ticker.change} />
              ))}
            </PriceTickerWrapper>
            <Outlet />
          </WalletContext.Provider>
        </WalletBody>
      </main>

      <aside className="hidden tbl:block w-80 pc:pt-0">
        <div className="sticky top-0 flex flex-col gap-y-5 p-3">
          <SearchBarInitiator />
          <WalletSidebar account={account} />
        </div>
      </aside>
    </React.Fragment>
  );
}

interface WalletProviderProps {
  children: React.ReactNode;
}

export function WalletHeader({ children }: WalletProviderProps) {
  return (
    <div className="flex flex-col h-12 pl-2 mt-[48px] sm:mt-0 font-large bg-pri dark:bg-pri-d border-b border-pri dark:border-pri-d sticky top-0 z-50">
      {children}
    </div>
  );
}

export function WalletHeaderLabel({ children }: WalletProviderProps) {
  return <h1 className="flex items-center pl-2 font-bold">{children}</h1>;
}

export function WalletBody({ children }: WalletProviderProps) {
  return <div className="m-3 p-1">{children}</div>;
}

interface WalletSectionProps {
  title: string;
  icon?: string;
  action?: React.ReactNode;
  children: React.ReactNode;
}

export function WalletSection({ title, icon, action, children }: WalletSectionProps) {
  return (
    <div className="flex flex-col py-7">
      <div className="flex flex-row justify-between items-center gap-x-3">
        <div className="flex flex-row items-center gap-3 pb-3">
          <h2 className="font-bold text-lg">{title}</h2>
          {icon && (
            <React.Fragment>
              <strong>—</strong>
              <div className="flex pt-[1px]">
                <img src={icon} alt="" width="auto" height={16} className="h-4" />
              </div>
            </React.Fragment>
          )}
        </div>

        {action ? action : null}
      </div>

      <div className="flex flex-col mt-2">{children}</div>
    </div>
  );
}
