import { useState } from "react";
import { useGetRedirectTo, writeLocalStorageAccounts } from "~/routes/login";
import * as keychain from "~/utils/keychain";
import * as hivesigner from "~/utils/hivesigner";
import HiveSign from "./HiveSign";
import KeychainAccountModal from "./hive/KeychainAccountModal";
import { useNavigate } from "@remix-run/react";
import { decryptFromKeystore } from "@xchainjs/xchain-crypto";
import { toast } from "react-toastify";

export default function HiveLogin() {
  const redirectTo = useGetRedirectTo();
  const navigate = useNavigate();

  const [keychainVisibility, setKeychainVisibilty] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [keychainLoading, setKeychainLoading] = useState(false);
  const [hivesignerLoading, setHivesignerLoading] = useState(false);
  const [keystore, setKeystore] = useState();
  const [keystorePass, setKeystorePass] = useState("");

  const handleKeychainLogin = async () => {
    if (accountName === "") {
      return;
    }

    setKeychainLoading(true);

    if (keystore && keystorePass) {
      const _keystore = await keystore.text();
      let wallet;
      try {
        wallet = await decryptFromKeystore(JSON.parse(_keystore), keystorePass);

        const message = await keychain.createSignToken(accountName);
        void (async function () {
          window.localStorage.setItem("activeAccount", message);
        })();

        writeLocalStorageAccounts(accountName, "keychain");

        const formData = new FormData();
        formData.append(
          "message",
          JSON.stringify({
            signature: message,
            phrase: wallet
          })
        );
        formData.append("accountName", accountName);
        formData.append("redirectTo", redirectTo);
        let response = await fetch("/login", {
          method: "POST",
          body: formData,
          redirect: "follow"
        });
        window.location.href = response.url;
      } catch {
        return toast(`Password doesn't match the ${keystore?.name}'s password!`, {
          type: "error",
          theme: "light",
          autoClose: 3_000
        });
      }
    }

    try {
      const message = await keychain.createSignToken(accountName);
      void (async function () {
        window.localStorage.setItem("activeAccount", message);
      })();

      writeLocalStorageAccounts(accountName, "keychain");

      const formData = new FormData();
      formData.append(
        "message",
        JSON.stringify({
          signature: message
        })
      );
      formData.append("accountName", accountName);
      formData.append("redirectTo", redirectTo);
      let response = await fetch("/login", {
        method: "POST",
        body: formData,
        redirect: "follow"
      });
      window.location.href = response.url;
    } catch (err) {
      setKeychainLoading(false);
    }

    setKeychainLoading(false);
  };

  const handleHivesignerLogin = async () => {
    setHivesignerLoading(true);

    try {
      hivesigner.createSignToken();
    } catch (err) {
      setHivesignerLoading(false);
    }

    setHivesignerLoading(false);
  };

  return (
    <div className="flex flex-1 items-center justify-around gap-x-4 -mt-2">
      <HiveSign method="keychain" onClick={() => setKeychainVisibilty(true)} loading={keychainLoading} />
      <HiveSign method="hivesigner" onClick={() => handleHivesignerLogin()} loading={hivesignerLoading} />
      <HiveSign
        method="keystore"
        onClick={() => navigate(`/keystore?redirectTo=${redirectTo}&action=login`)}
        loading={false}
      />

      <KeychainAccountModal
        visibility={keychainVisibility}
        username={accountName}
        loading={keychainLoading}
        setVisibility={setKeychainVisibilty}
        keystoreProps={{ setKeystore, setKeystorePass, keystore, keystorePass }}
        setUsername={(username: string) => setAccountName(username)}
        onSubmit={handleKeychainLogin}
      />
    </div>
  );
}
