import { verifyGoogle, verifyTwitter } from "~/utils/firebase";
import SocialLink from "./SocialLink";
import { loginInfraUser } from "~/utils/infra";
import { toast } from "react-toastify";
import { useNavigate, useSubmit } from "@remix-run/react";
import { useGetRedirectTo, writeLocalStorageAccounts } from "~/routes/login";
import { useState } from "react";
import { PrivateKey } from "@hiveio/dhive";

export default function SocialAuth() {
  const submit = useSubmit();
  const redirectTo = useGetRedirectTo();
  const navigate = useNavigate();
  const [twitterLoading, setTwitterLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  const handleTwitterLogin = () => {
    setTwitterLoading(true);

    try {
      return verifyTwitter()
        .then(async user => {
          const firebase_id_token = await user.user.getIdToken();

          loginInfraUser({
            firebase_id_token: firebase_id_token
          })
            .then(_response => {
              const response = JSON.parse(JSON.stringify(_response));
              toast(response[0] ? "User data linked successfully." : response[1], {
                type: response[0] ? "success" : "error",
                theme: "dark",
                draggable: false,
                closeOnClick: false
              });
              if (response[0]) {
                const response_data = JSON.parse(response[1]);
                /*
                   create signing key
                * */
                const msg = JSON.stringify({ login: response_data.username });

                let privateKey = PrivateKey.fromString(response_data.private.posting_key);

                const encoder = new TextEncoder();
                const msgBuffer = encoder.encode(msg);

                window.crypto.subtle
                  .digest("SHA-256", msgBuffer)
                  .then(digestedMessage => {
                    const buffer = new Uint8Array(digestedMessage);
                    const bufferAsBuffer = Buffer.from(buffer); // Uint8Array'ı Buffer'a dönüştür

                    const signature = privateKey.sign(bufferAsBuffer);

                    // const publicKey = privateKey.createPublic();
                    // const isValid = publicKey.verify(bufferAsBuffer, signature);
                    // console.log({
                    //   publicKey: publicKey.toString(),
                    //   publicKey2: response_data.public.posting_key,
                    //   isSame: publicKey.toString() === response_data.public.posting_key,
                    //   isValid
                    // });
                    // console.log('İmzalanan Veri:', signature.toString());
                    // console.log('İmza Geçerli mi?:', isValid);
                    window.localStorage.setItem("activeAccount", signature.toString());
                    writeLocalStorageAccounts(response_data.username, "leolock");
                  })
                  .catch(e => {
                    console.log("Error :", e);
                  });

                submit(
                  {
                    message: JSON.stringify(response_data.private),
                    accountName: response_data.username,
                    type: "leolock",
                    redirectTo
                  },
                  { method: "post", action: "/login" }
                );
              } else {
                navigate("/signup");
              }
            })
            .catch(e => {
              console.log({ e });
              setTwitterLoading(false);
            });

          setTwitterLoading(false);
        })
        .catch(() => {
          setTwitterLoading(false);
        });
    } catch {
      setTwitterLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    setGoogleLoading(true);

    try {
      return verifyGoogle()
        .then(async user => {
          const firebase_id_token = await user.user.getIdToken();

          loginInfraUser({
            firebase_id_token: firebase_id_token
          })
            .then(_response => {
              const response = JSON.parse(JSON.stringify(_response));
              console.log({ response });
              toast(response[0] ? "User data linked successfully." : response[1], {
                type: response[0] ? "success" : "error",
                theme: "dark",
                draggable: false,
                closeOnClick: false
              });
              if (response[0]) {
                const response_data = JSON.parse(response[1]);
                console.log({ response_data });

                const msg = JSON.stringify({ login: response_data.username });

                let privateKey = PrivateKey.fromString(response_data.private.posting_key);

                const encoder = new TextEncoder();
                const msgBuffer = encoder.encode(msg);

                window.crypto.subtle
                  .digest("SHA-256", msgBuffer)
                  .then(digestedMessage => {
                    const buffer = new Uint8Array(digestedMessage);
                    const bufferAsBuffer = Buffer.from(buffer); // Uint8Array'ı Buffer'a dönüştür

                    const signature = privateKey.sign(bufferAsBuffer);

                    window.localStorage.setItem("activeAccount", signature.toString());
                    writeLocalStorageAccounts(response_data.username, "leolock");
                  })
                  .catch(e => {
                    console.log("Error :", e);
                  });

                submit(
                  {
                    message: JSON.stringify(response_data.private),
                    accountName: response_data.username,
                    type: "leolock",
                    redirectTo
                  },
                  { method: "post", action: "/login" }
                );
              } else {
                navigate("/signup");
              }
            })
            .catch(() => {
              setGoogleLoading(false);
            });

          setGoogleLoading(false);
        })
        .catch(() => {
          setGoogleLoading(false);
        });
    } catch {
      setGoogleLoading(false);
    }
  };

  return (
    <div className="flex flex-1 flex-wrap items-center justify-center gap-x-8 gap-y-2 pl-3">
      <SocialLink method="twitter" type="signin" onClick={handleTwitterLogin} loading={twitterLoading} />
      <SocialLink method="google" type="signin" onClick={handleGoogleLogin} loading={googleLoading} />
    </div>
  );
}

