import type { AccountSettings } from "~/contexts";
import { cache } from "./cache";
import { getCookie } from "./cookie";
import { leocache } from "./leocache";
import type { ParsedAccount } from "./hive";

export const API = "https://inleo.io/leoinfra";
//export const API = "http://localhost:8090";

export interface InfraSettingsResponse {
  account: string;
  settings: AccountSettings;
}

export async function postData(url = "", data = {}, no_serialize?: boolean) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });

  try {
    return await response.json();
  } catch {
    return;
  }
  // return no_serialize ? response : await response.json(); // parses JSON response into native JavaScript objects
}

async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export interface GetDraft {
  authorization: Authorization;
}

export interface SaveDraft {
  draft: Draft;
  authorization: Authorization;
  draft_id: string;
}

export interface Draft {
  body: string;
  title: string;
  permlink: string;
}

export interface Authorization {
  signature: string;
  account: string;
  public_key: string;
}

interface UserSignData {
  username: string;
  firebase_uid: string;
  firebase_id_token: string;
  public: {
    owner_key: string;
    posting_key: string;
    active_key: string;
  };
  private: {
    owner_key: string;
    posting_key: string;
    active_key: string;
  };
  memo: string;
}

interface UserLoginData {
  firebase_id_token: string;
}

export function getSignature() {
  const signature = window.localStorage.getItem("activeAccount");

  if (signature) {
    return signature;
  } else {
    return alert("No signature present to connect.");
  }
}

export async function prepareAuthorization(
  activeAccount:
    | {
        name: any;
        posting: { key_auths: any[][] };
        account: any;
      }
    | ParsedAccount
): Promise<Authorization> {
  const { auth, proxy } = getCookie("__session");

  let timestamp;

  try {
    timestamp = JSON.parse(atob(auth))?.timestamp;
  } catch {
    timestamp = 0;
  }

  return {
    public_key: activeAccount?.posting?.key_auths[0][0],
    account: activeAccount?.name,
    signature: await getSignature(),
    hivesigner: proxy === "hivesigner",
    timestamp: timestamp || 0
  };
}

export async function removeInfraTemplate(activeAccount: any, template_id: string) {
  return postData(
    `${API}/templates/delete`,
    { authorization: await prepareAuthorization(activeAccount), template_id },
    true
  );
}

export async function getInfraTemplates(activeAccount: any) {
  return postData(`${API}/templates`, { authorization: await prepareAuthorization(activeAccount) }, true);
}

export async function saveInfraTemplate(activeAccount: any, template: any, template_id: any) {
  return postData(
    `${API}/templates/save`,
    {
      template: template,
      authorization: await prepareAuthorization(activeAccount),
      template_id
    },
    true
  );
}

export async function getInfraSchedule(activeAccount: any) {
  return postData(`${API}/schedule`, await prepareAuthorization(activeAccount), true);
}

export async function deleteInfraSchedule(activeAccount: any, oid: string) {
  return postData(
    `${API}/schedule/delete`,
    {
      authorization: await prepareAuthorization(activeAccount),
      oid
    },
    true
  );
}

export async function saveInfraDraft(saveDraft: SaveDraft) {
  return await postData(`${API}/drafts/save`, saveDraft, true);
}

export async function allInfraDraft(saveDraft: SaveDraft) {
  return postData(`${API}/drafts`, saveDraft);
}

export async function deleteInfraDraft(saveDraft: SaveDraft) {
  return postData(`${API}/drafts/delete`, saveDraft);
}

export async function saveInfraSchedule(saveDraft: SaveDraft) {
  return postData(`${API}/schedule/save`, saveDraft);
}

export async function signInfraUser(userSignData: UserSignData) {
  return postData(`${API}/auth/signup`, userSignData);
}

export async function loginInfraUser(userSignData: UserLoginData) {
  return postData(`${API}/auth/login`, userSignData);
}

const settingsWarmup = (account: String) => {
  setTimeout(() => {
    fetch(`/helpers/settings/${account}`).then(() => {});
  }, 250);
};

export async function getInfraSettings(accountName: string): Promise<InfraSettingsResponse> {
  return leocache.getInfraSettings(accountName);
}

export async function updateInfraSettings(
  accountName: string,
  updateData: {
    settings: AccountSettings;
    authorization: {
      account: string;
      signature: string;
      public_key: string;
      hivesigner: boolean;
    };
  }
): Promise<Response> {
  settingsWarmup(accountName);
  return await postData(`${API}/settings/${accountName}`, updateData, true);
}

export async function resetInfraSettings(
  accountName: string,
  authorization: {
    account: string;
    signature: string;
    public_key: string;
    hivesigner: boolean;
  }
): Promise<Response> {
  settingsWarmup(accountName);
  return await postData(`${API}/settings/reset/${accountName}`, authorization, true);
}
