import { toast } from "react-toastify";

export function isKeychainEnabled() {
  return (window as any).hive_keychain !== undefined;
}

export function createSignToken(accountName: string): Promise<string> {
  if ((window as any).hive_keychain === undefined) return Promise.reject(new Error("Keychain not installed"));

  return new Promise((resolve, reject) => {
    (window as any).hive_keychain.requestSignBuffer(
      accountName,
      JSON.stringify({ login: accountName }),
      "Posting",
      (response: any) => {
        if (!response.success) {
          return reject();
        }
        resolve(response.result);
      },
      null,
      "Login to InLeo"
    );
  });
}

export enum KeychainKeyType {
  Posting = "Posting",
  Active = "Active"
}

export function broadcast(account: string, key: KeychainKeyType, operations: any[]): Promise<void> {
  const isComment = operations[0][0] === "comment";
  const secondOperationExists = !!operations[1];

  return new Promise((resolve, reject) => {
    (window as any).hive_keychain.requestBroadcast(account, operations, key, (response: any) => {
      if (!response.success) {
        toast(`${(response?.message || "").replaceAll("<br/", "")}`, {
          type: "error",
          autoClose: 3000
        });
        return reject(response.error);
      }
      resolve();
    });
  });
}

export function customJson(account: string, key: KeychainKeyType, operation): Promise<void> {
  return new Promise((resolve, reject) => {
    (window as any).hive_keychain.requestCustomJson(
      account,
      operation.id,
      key,
      operation.json,
      "InLeo Poll Voting TX",
      (response: any) => {
        if (!response.success) {
          return reject(response.error);
        }
        resolve();
      },
      "ssc-mainnet-hive"
    );
  });
}
