export enum Symbol {
  HIVE = "HIVE",
  HBD = "HBD",
  VESTS = "VESTS",
  SPK = "SPK"
}

export enum NaiMap {
  "@@000000021" = "HIVE",
  "@@000000013" = "HBD",
  "@@000000037" = "VESTS"
}

export interface Asset {
  amount: number;
  symbol: Symbol;
}

export default function parseAsset(sval: string | any): Asset {
  if (!sval) return { amount: 0, symbol: "" };

  if (typeof sval === "string") {
    const sp = sval.split(" ");
    return {
      amount: parseFloat(sp[0]),
      symbol: Symbol[sp[1] as keyof typeof Symbol]
    };
  } else {
    return {
      amount: parseFloat(sval?.amount?.toString()) / Math.pow(10, sval?.precision),
      symbol: NaiMap[sval?.nai as keyof typeof NaiMap]
    };
  }
}

export function parseEngineAsset(sval: string | any): { amount: number; symbol: string } {
  if (!sval) return { amount: 0, symbol: "" };

  if (typeof sval === "string") {
    const sp = sval.split(" ");
    return {
      amount: parseFloat(sp[0]),
      symbol: sp[1]
    };
  }

  return { amount: 0, symbol: "" };
}
