import { useState, useEffect, memo } from "react";
import PremiumBadge from "../Premium";
import { cache } from "~/utils/cache";
import classNames from "classnames";
import { Link } from "@remix-run/react";
import { getPostingJsonMetadata } from "~/utils/parse";
import { CreatorSubBadge } from "../CreatorSub";
import { useCreatorSubscriptions } from "~/hooks/useSubscriptions";

interface DisplayNameProps {
  name: string;
  className?: string;
  premium?: boolean | undefined;
  subscriptions?: string[];
  authorName?: string;
  disableRedirect?: boolean;
}

const DisplayName = memo(
  ({ name, className, authorName, premium: isPremium, subscriptions, disableRedirect }: DisplayNameProps) => {
    const [premium, setPremium] = useState<boolean>(isPremium || false);

    const creatorSubs = subscriptions ? subscriptions : useCreatorSubscriptions(authorName);
    const [displayname, setDisplayName] = useState<string>(name || authorName);

    useEffect(() => {
      if (!authorName || isPremium !== undefined) return;
      // TODO : delete it after sure
      // cache.getPremium(authorName).then(premium => {
      //   setPremium(premium.is_premium);
      // });

      cache.getAccount(authorName).then(account => {
        setPremium(!!account?.premium.is_premium);
        if (account.posting_json_metadata) {
          const accountData = getPostingJsonMetadata(account);
          accountData.profile.name && setDisplayName(accountData.profile.name);
        }
      });
    }, [authorName, isPremium]);

    const Wrapper = props =>
      disableRedirect ? (
        <div className={classNames("flex items-center gap-x-1 font-bold text-pri dark:text-pri-d", className)}>
          {props.children}
        </div>
      ) : (
        <Link
          className={classNames(
            "flex items-center gap-x-1 font-bold text-pri dark:text-pri-d hover:underline hover:cursor-pointer",
            className
          )}
          itemProp="url"
          to={`/profile/${authorName}`}
          prefetch="intent"
        >
          {props.children}
        </Link>
      );

    return (
      <Wrapper>
        <span className="leading-none pr-0.5" itemProp="name">
          {displayname}
        </span>
        {premium || isPremium ? (
          <span className="flex">
            <PremiumBadge />
          </span>
        ) : null}

        {creatorSubs && creatorSubs.length > 0 ? (
          <span className="flex">
            <CreatorSubBadge />
          </span>
        ) : null}
      </Wrapper>
    );
  }
);

DisplayName.displayName = "DisplayName";
export default DisplayName;

