import { broadcast, customJson, KeychainKeyType } from "~/utils/keychain";
import { broadcast as hivesigner_broadcast, hivesigner, custom_json as hivesigner_customJson } from "./hivesigner";
import type { AuthorPerm, HiveContent, ParsedAccount } from "~/utils/hive";
import { addNoiseToPermlink, fetchAccountPosts, THREADS_APP, THREADS_AUTHOR } from "~/utils/hive";

import { buildLinksImagesArray } from "~/utils/markdown";
import { getCookie } from "./cookie";
import type { Client } from "hivesigner/lib/client";
import type { ThreadContent } from "./thread";
import { toast } from "react-toastify";
import type { PollOptions } from "~/components/format/Polls";
import { PrivateKey, Client as dHiveClient } from "@hiveio/dhive";
import { encryptPostBody } from "./leocache";
import { prepareAuthorization } from "./infra";

export const DHIVE = () => {
  const opts = {
    addressPrefix: "STM",
    chainId: "beeab0de00000000000000000000000000000000000000000000000000000000"
  };
  // return new dHiveClient("https://api.deathwing.me", opts);
  return new dHiveClient("https://hive.inleo.io", opts);
};

export async function editThread(
  threadContent: ThreadContent | HiveContent,
  newBody: string,
  activeAccount?: ParsedAccount | null
) {
  let metadata = {};
  try {
    metadata = JSON.parse(threadContent?.content?.json_metadata || threadContent.json_metadata);
  } catch {
    metadata = {};
  }

  let final_body = newBody || "";

  if (metadata.encrypted) {
    if (!activeAccount) return;

    const authorization = await prepareAuthorization(activeAccount);
    const encrypted_body = await encryptPostBody(authorization, newBody);
    final_body = encrypted_body;
  }

  const meta = JSON.stringify(threadContent?.content?.json_metadata || threadContent.json_metadata);

  const commentOperation = [
    "comment",
    {
      author: threadContent?.content?.author || threadContent?.author,
      permlink: threadContent?.content?.permlink || threadContent?.permlink,
      title: threadContent?.content?.title || threadContent?.title || "",
      json_metadata: meta || "",
      body: final_body,
      parent_author: threadContent?.content?.parent_author || threadContent?.parent_author || "",
      parent_permlink: threadContent?.content?.parent_permlink || threadContent?.parent_permlink || ""
    }
  ] as any;

  return await broadcastAll({
    author: threadContent?.content?.author || threadContent?.author,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [commentOperation]
  });
}

// don't export this
async function postThread(
  author: string,
  body: string,
  parentAuthorPerm: AuthorPerm,
  footer?: Boolean,
  pollOptions?: Object,
  dimensions?: any,
  encryption?: boolean,
  activeAccount?: any
) {
  return new Promise(async (resolve, reject) => {
    const parsedAuthorName = parentAuthorPerm?.author?.replaceAll(".", "-");
    const permlink = addNoiseToPermlink({
      author,
      permlink: `re-${parsedAuthorName?.replaceAll("@", "")}`
    });

    let isPoll;
    let last_irreversible_block_num;

    if (pollOptions) {
      isPoll = pollOptions["1"] !== "" && pollOptions["2"] !== "";
      last_irreversible_block_num = "";
    } else {
      isPoll = false;
    }

    let final_body = body;

    if (encryption === true && activeAccount) {
      try {
        const authorization = await prepareAuthorization(activeAccount);
        const encrypted_body = await encryptPostBody(authorization, body);
        final_body = encrypted_body;
      } catch {
        return reject(new Error("Couldn't encrypt thread body, please try again."));
      }
    }

    const commentOperation = [
      "comment",
      {
        author: author || activeAccount?.name,
        permlink,
        title: `@${author} "${body.slice(0, 50)}..."`,
        json_metadata: JSON.stringify({
          app: THREADS_APP,
          format: "markdown",
          tags: ["leofinance"],
          canonical_url: `https://inleo.io/threads/view/${author}/${permlink}`,
          ...(buildLinksImagesArray(final_body) ?? {}),
          isPoll: isPoll,
          pollOptions: pollOptions ?? {},
          startingBlock: last_irreversible_block_num,
          dimensions: dimensions || [],
          ...(encryption ? { encrypted: true } : {})
        }),
        body: final_body,
        parent_author: parentAuthorPerm?.author,
        parent_permlink: parentAuthorPerm?.permlink
      }
    ] as any;

    const commentOptionsOperation = [
      "comment_options",
      {
        author,
        permlink,
        allow_votes: true,
        allow_curation_rewards: true,
        extensions: [],
        max_accepted_payout: "1000000.000 HBD",
        percent_hbd: 10_000
      }
    ] as any;

    // For quick testing without publishing to hive
    // return resolve(commentOperation[1]);

    try {
      await broadcastAll({
        author,
        hivesignerClient: hivesigner,
        type: KeychainKeyType.Posting,
        operations: [commentOperation, commentOptionsOperation]
      });
      // .catch(async error => {
      //   // alert(error?.message || error);
      //   toast(error.message || "Something went wrong, please re-login and try again.", {
      //     type: "error",
      //     autoClose: 5000
      //   });
      //   reject(error);
      //   console.log({ POSTING_ERROR: error });
      // });
    } catch (err) {
      console.log(err);
      toast(err.message || "Something went wrong, please re-login and try again.", { type: "error", autoClose: 5000 });
      // alert(err?.message || err);
      reject(err);
    }

    if (typeof window !== "undefined" && window?.localStorage) {
      window.localStorage.removeItem("threads-children");
    }

    resolve(commentOperation[1]);
    // return commentOperation[1];
  });
}

export interface PublishPost {
  author: string;
  permlink: string;
  body: string;
  title: string;
  dimensions: any;
  settings: PostSettings;
  pollOptions?: PollOptions;
  encryption?: boolean;
}

interface PostSettings {
  beneficiaries: Beneficiary[];
  reward_option: string;
  tags: string[];
}

interface Beneficiary {
  account: string;
  weight: number;
}

async function publishPost({
  author,
  permlink,
  body,
  title,
  settings,
  dimensions,
  pollOptions,
  encryption
}: PublishPost) {
  const commentOperation = [
    "comment",
    {
      author,
      permlink,
      title: title || "",
      json_metadata: JSON.stringify({
        app: THREADS_APP,
        format: "markdown",
        tags: settings?.tags ?? ["hive-167922", "leofinance"],
        canonical_url: `https://inleo.io/@${author}/${permlink}`,
        ...(buildLinksImagesArray(body) ?? {}),
        isPoll: !!pollOptions,
        pollOptions,
        dimensions,
        ...(encryption ? { encrypted: true } : {})
      }),
      body,
      parent_author: encryption ? "leosubscriptions" : "",
      parent_permlink: encryption
        ? "container-test-aqy"
        : settings?.editMode
        ? ""
        : settings.tags[0]
        ? settings.tags[0].toLowerCase()
        : "hive-167922"
    }
  ] as any;

  let isBurn = settings?.reward_option === "reward_option_burn";

  if (isBurn) {
    settings.beneficiaries = [
      {
        account: "null",
        weight: 100
      }
    ];
  }
  const commentOptionsOperation = [
    "comment_options",
    {
      author,
      permlink,
      allow_votes: true,
      allow_curation_rewards: true,
      percent_hbd: 0,
      extensions: settings.beneficiaries.length
        ? [
            [
              0,
              {
                beneficiaries: settings?.beneficiaries
                  .map(beneficiary => {
                    return {
                      ...beneficiary,
                      weight: beneficiary?.weight * 100
                    };
                  })
                  .sort((a, b) => a.account.localeCompare(b.account))
              }
            ]
          ]
        : [],
      max_accepted_payout: "1000000.000 HBD",
      percent_hbd: 10_000
    }
  ] as any;

  if (settings?.reward_option === "reward_option_decline") {
    commentOptionsOperation[1].max_accepted_payout = "0.000 HBD";
  } else if (settings.reward_option === "reward_option_100_hp") {
    commentOptionsOperation[1].percent_hbd = 0;
  }

  if (settings?.beneficiaries?.length >= 1) {
    settings.beneficiaries = settings.beneficiaries.concat();
  }

  let donateAccount = null;
  if (settings?.reward_option === "reward_option_burn") {
    donateAccount = "null";
  } else if (settings?.reward_option === "reward_option_donate_to_hive_fund") {
    donateAccount = "steem.dao";
  }

  //console.log(cache.setContent(stringToAuthorPerm(`${author}/${permlink}`), commentOperation[1] as any));

  //console.log({ permlink, noisedPermlink });
  // for quick testings
  // return Promise.resolve(commentOperation[1]);

  return broadcastAll({
    author,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: settings?.editMode ? [commentOperation] : [commentOperation, commentOptionsOperation]
  });
}

export type AccountSession = { proxy: string; posting_key: string; active_key: string };
interface BroadcastAll {
  author?: string;
  hivesignerClient?: Client;
  type: KeychainKeyType;
  operations: any[];
  session?: AccountSession;
}

export async function broadcastAll({ author, hivesignerClient, type, operations, session }: BroadcastAll) {
  let auth, proxy;

  if (session) {
    auth = JSON.stringify({
      posting_key: session.posting_key,
      active_key: session.active_key
    });
    proxy = session.proxy;
  } else {
    const { auth: auth_from_cookie, proxy: proxy_from_cookie } = getCookie("__session");

    auth = auth_from_cookie;
    proxy = proxy_from_cookie;
  }

  if (proxy === "hivesigner") {
    hivesigner.accessToken = auth;
    if (!hivesignerClient) return;
    return hivesigner_broadcast(hivesignerClient, type, operations).then(result => result);
  } else if (proxy === "keychain") {
    if (!author) return;
    const result = await broadcast(author, type, operations);
    return result;
  } else if (proxy === "leolock") {
    let posting_key, active_key;

    try {
      const parsed_auth = JSON.parse(auth);
      posting_key = parsed_auth.posting_key;

      if (parsed_auth.active_key) {
        active_key = parsed_auth.active_key;
      }
    } catch {
      return toast("Couldn't find account keys, please re-login.", {
        type: "error",
        autoClose: 3000
      });
    }
    const dhiveClient = DHIVE();

    // Posting key
    const privateKey =
      type === KeychainKeyType.Active ? PrivateKey.fromString(active_key) : PrivateKey.fromString(posting_key);

    if (!privateKey) {
      alert("Active key is not present.");
    }

    return await dhiveClient.broadcast.sendOperations(operations, privateKey);
  }
}

interface VoteAll {
  account?: string;
  authorPerm: AuthorPerm;
  weight: number;
}

export async function claimRewards(author: string, hive: string, hbd: string, vests: string) {
  const operations = [
    [
      "claim_reward_balance",
      {
        account: author,
        reward_hive: hive,
        reward_hbd: hbd,
        reward_vests: vests
      }
    ]
  ];

  const { auth, proxy } = getCookie("__session");
  // TODO: Add toastify here for all transactions.
  if (proxy === "hivesigner") {
    hivesigner.accessToken = auth;
    if (!hivesigner) return;
    return hivesigner_broadcast(hivesigner, KeychainKeyType.Posting, operations).then(result => result);
  } else if (proxy === "keychain") {
    if (!author) return;
    const result = await broadcast(author, KeychainKeyType.Posting, operations);
    return result;
  } else if (proxy === "leolock") {
    const { posting_key } = JSON.parse(auth);
    const dhiveClient = DHIVE();
    // Posting key
    const privateKey = PrivateKey.fromString(posting_key);

    if (!privateKey) {
      alert("Active key is not present.");
    }

    return await dhiveClient.broadcast.sendOperations(operations, privateKey);
  }
}

export async function readNotifications(account: string) {
  const formattedTime = new Date().toISOString().replace(/\.\d+Z$/, "");

  const operations = [
    [
      "custom_json",
      {
        required_auths: [],
        required_posting_auths: [account],
        id: "notify",
        json: `["setLastRead",{"date":"${formattedTime}"}]`
      }
    ]
  ];

  return broadcastAll({
    author: account,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: operations
  });
}

export const handleProxyChange = async (account: string, proxy: string) => {
  const operations = [
    "account_witness_proxy",
    {
      account,
      proxy
    }
  ];

  return await broadcastAll({
    author: account,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Active,
    operations: [operations]
  });
};

export async function vote(account: string, authorPerm: AuthorPerm, weight: number) {
  const { auth, proxy } = getCookie("__session");

  if (proxy === "hivesigner") {
    hivesigner.accessToken = auth;
    const operation = [
      "vote",
      {
        voter: account,
        author: authorPerm.author,
        permlink: authorPerm.permlink,
        weight: weight
      }
    ];

    return hivesigner_broadcast(hivesigner, KeychainKeyType.Posting, [operation]).then(result => result);
  } else if (proxy === "keychain") {
    return new Promise<void>((resolve, reject) => {
      (window as any).hive_keychain.requestVote(
        account,
        authorPerm.permlink,
        authorPerm.author,
        weight,
        (response: any) => {
          if (!response.success) {
            return reject();
          }
          resolve();
        }
      );
    });
  }
  if (proxy === "leolock") {
    const { posting_key } = JSON.parse(auth);

    const dhiveClient = DHIVE();

    // Posting key
    const privateKey = PrivateKey.fromString(posting_key);

    const operation = [
      "vote",
      {
        voter: account,
        author: authorPerm.author,
        permlink: authorPerm.permlink,
        weight: weight
      }
    ];

    return dhiveClient.broadcast.sendOperations([operation], privateKey).then(console.log);
  }
}

// use these instead, or add more below if needed
export async function postThreadMain(
  author: string,
  body: string,
  pollOptions?: object,
  dimensions?: any,
  encryption?: boolean,
  activeAccount?: any
) {
  return new Promise(async (resolve, reject) => {
    try {
      const containers = await fetchAccountPosts(THREADS_AUTHOR, 1);

      if (!containers) {
        return reject(
          new Error("Something went wrong while fetching containers, please refresh the page and try again.")
        );
      }

      resolve(
        await postThread(
          author,
          body,
          { author: THREADS_AUTHOR, permlink: containers[0].permlink },
          false,
          pollOptions,
          dimensions,
          encryption,
          activeAccount
        )
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function postThreadReply(
  author: string,
  body: string,
  parentAuthorPerm: AuthorPerm,
  postFooter?: Boolean,
  pollOptions?: Object
) {
  return postThread(author, body, parentAuthorPerm, postFooter, pollOptions);
}

export function postMain(postParams: PublishPost) {
  return publishPost(postParams);
}

export function followAccount(follower: string, following: string): any {
  const custom_json = [
    "custom_json",
    {
      required_auths: [],
      required_posting_auths: [follower],
      id: "follow",
      json: JSON.stringify([
        "follow",
        {
          follower: follower,
          following: following,
          what: ["blog"]
        }
      ])
    }
  ];

  return broadcastAll({
    author: follower,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [custom_json]
  });
}

export function unFollowAccount(follower: string, following: string): any {
  const custom_json = [
    "custom_json",
    {
      required_auths: [],
      required_posting_auths: [follower],
      id: "follow",
      json: JSON.stringify([
        "follow",
        {
          follower: follower,
          following: following,
          what: []
        }
      ])
    }
  ];

  return broadcastAll({
    author: follower,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [custom_json]
  });
}

export async function updateAccount(account: string, metadata: string, author: string, session?: any): Promise<any> {
  const custom_json = [
    "account_update2",
    {
      account: account,
      json_metadata: "",
      posting_json_metadata: metadata,
      extensions: []
    }
  ];

  return await broadcastAll({
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [custom_json],
    author: author,
    session
  });
}

interface deleteComment {
  author: string;
  permlink: string;
}

export async function deleteComment({ author, permlink }: deleteComment) {
  const delete_comment_operation = [
    "delete_comment",
    {
      author: author,
      permlink: permlink
    }
  ] as any;

  return broadcastAll({
    author: author,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [delete_comment_operation]
  });
}

interface reblogComment {
  account: string;
  author: string;
  permlink: string;
}

export async function reblogComment({ account, author, permlink }: reblogComment) {
  const reblog_comment_operation = [
    "custom_json",
    {
      required_auths: [],
      required_posting_auths: [account],
      id: "follow",
      json: JSON.stringify(["reblog", { account, author, permlink }])
    }
  ] as any; // as reblog operation

  return broadcastAll({
    author: account,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [reblog_comment_operation]
  });
}

interface pollVote {
  authorPerm: AuthorPerm; // The poll which gets broadcasted
  author: string; // The one who is broadcasting the op
  option: number;
}

export async function pollVote({ author, authorPerm, option }: pollVote) {
  const { auth, proxy } = getCookie("__session");

  const prepareId = `leo_poll_${authorPerm.permlink}`;

  const custom_json = {
    required_auths: [],
    required_posting_auths: [author],
    id: prepareId,
    json: JSON.stringify({
      option: option
    })
  };

  if (proxy === "hivesigner") {
    hivesigner.accessToken = auth;
    hivesigner_customJson(["custom_json", custom_json]);
  }
  if (proxy === "keychain") {
    return customJson(author, KeychainKeyType.Active, custom_json);
  }
  if (proxy === "leolock") {
    const { active_key, posting_key } = JSON.parse(auth);
    console.log(auth);

    const dhiveClient = DHIVE();

    // Active Key
    const privateKey = PrivateKey.fromString(active_key || posting_key);

    return dhiveClient.broadcast.sendOperations([["custom_json", custom_json]], privateKey).then(console.log);
  }
}

interface HiveEngineTokenTransferParams {
  symbol?: string;
  to?: string;
  quantity: number;
  memo?: string;
  txID?: string;
}

export const hiveEngineToken = (author: string, type: string, payload: HiveEngineTokenTransferParams) => {
  const { auth, proxy } = getCookie("__session");

  const operationTypes = {
    transfer: {
      contractName: "tokens",
      contractAction: "transfer",
      contractPayload: {
        symbol: payload.symbol,
        to: payload.to,
        quantity: Number(payload.quantity).toFixed(3),
        memo: payload.memo
      }
    },
    stake: {
      contractName: "tokens",
      contractAction: "stake",
      contractPayload: {
        to: payload.to,
        symbol: payload.symbol,
        quantity: Number(payload.quantity).toFixed(3)
      }
    },
    unstake: {
      contractName: "tokens",
      contractAction: "unstake",
      contractPayload: {
        symbol: payload.symbol,
        quantity: Number(payload.quantity).toFixed(3)
      }
    },
    delegate: {
      contractName: "tokens",
      contractAction: "delegate",
      contractPayload: {
        to: payload.to,
        symbol: payload.symbol,
        quantity: Number(payload.quantity).toFixed(3)
      }
    },
    cancel_unstake: {
      contractName: "tokens",
      contractAction: "cancelUnstake",
      contractPayload: {
        txID: payload.txID
      }
    }
  };

  const custom_json = [
    "custom_json",
    {
      required_auths: [author],
      required_posting_auths: [],
      id: "ssc-mainnet-hive",
      json: JSON.stringify(operationTypes[type])
    }
  ];

  if (proxy === "hivesigner") {
    hivesigner.accessToken = auth;
    hivesigner_customJson(custom_json);
  }

  if (proxy === "keychain") {
    const keychain = (window as any).hive_keychain;
    return new Promise((resolve, reject) => {
      if (type === "transfer") {
        keychain.requestSendToken(
          author,
          payload.to,
          Number(payload.quantity).toFixed(3),
          payload.memo,
          payload.symbol,
          (response: unknown) => {
            if (response.success) {
              resolve(response);
            } else {
              reject(response);
            }
          }
        );
      } else {
        keychain.requestBroadcast(author, [custom_json], "Active", (response: any) => {
          resolve(response);
        });
      }
    });
  }
  if (proxy === "leolock") {
    const { active_key } = JSON.parse(auth);

    const dhiveClient = DHIVE();

    if (active_key === "")
      toast("Active key is not present in LeoAuth please login with active key to do wallet transactions.", {
        type: "error"
      });
    // Active Key

    const privateKey = PrivateKey.fromString(active_key);

    return Promise.resolve(dhiveClient.broadcast.sendOperations([custom_json], privateKey));
  }
};

interface HiveTokenTransferParams {
  symbol?: string;
  to?: string;
  quantity: number;
  memo?: string;
  txID?: string;
}

export const hiveToken = async (author: string, type: string, payload: HiveTokenTransferParams) => {
  const { auth, proxy } = getCookie("__session");

  const operationTypes = {
    transfer: [
      "transfer",
      {
        from: author,
        to: payload.to,
        amount: `${Number(payload.quantity).toFixed(3)} ${payload.symbol}`,
        memo: payload.memo
      }
    ],
    power_up: [
      "transfer_to_vesting",
      {
        from: author,
        to: payload.to,
        amount: `${Number(payload.quantity).toFixed(3)} ${payload.symbol}`
      }
    ],
    power_down: [
      "withdraw_vesting",
      {
        account: author,
        vesting_shares: `${payload.quantity} VESTS`
      }
    ],
    stop_power_down: [
      "withdraw_vesting",
      {
        account: author,
        vesting_shares: "0.000000 VESTS"
      }
    ],
    transfer_to_savings: [
      "transfer_to_savings",
      {
        from: author,
        to: payload.to,
        amount: payload.quantity,
        memo: ""
      }
    ],
    delegate: [
      "delegate_vesting_shares",
      {
        delegator: author,
        delegatee: payload.to,
        vesting_shares: `${payload.quantity} VESTS`
      }
    ]
  };

  if (proxy === "hivesigner") {
    hivesigner.accessToken = auth;
    return new Promise((resolve, reject) => {
      hivesigner_customJson(operationTypes[type]);
    });
  }

  if (proxy === "keychain") {
    const keychain = (window as any).hive_keychain;

    return new Promise((resolve, reject) => {
      if (type === "transfer") {
        keychain.requestTransfer(
          author,
          payload.to,
          Number(payload.quantity).toFixed(3),
          payload.memo,
          payload.symbol,
          (response: unknown) => {
            if (response.success) {
              resolve(response);
            } else {
              reject(response);
            }
          }
        );
      } else {
        keychain.requestBroadcast(author, [operationTypes[type]], "Active", response => {
          if (response.success) {
            resolve(response);
          } else {
            reject(response);
          }
        });
      }
    });
  }

  if (proxy === "leolock") {
    const { active_key } = JSON.parse(auth);

    const dhiveClient = DHIVE();

    if (active_key === "")
      toast("Active key is not present in LeoAuth please login with active key to do wallet transactions.", {
        type: "error"
      });
    // Active Key

    const privateKey = PrivateKey.fromString(active_key);

    return Promise.resolve(dhiveClient.broadcast.sendOperations([operationTypes[type]], privateKey));
  }
};

export async function subscribeCommunities(communities: string[], account: string, session?: any) {
  const ops = communities.map(c => [
    "custom_json",
    {
      id: "community",
      json: JSON.stringify([
        "subscribe",
        {
          community: c
        }
      ]),
      required_auths: [],
      required_posting_auths: [account]
    }
  ]);

  return broadcastAll({
    author: account,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: ops,
    session
  });
}

export async function subscribeCommunity(community: string, account: string, session?: any) {
  const join_community_operation = [
    "custom_json",
    {
      id: "community",
      json: JSON.stringify([
        "subscribe",
        {
          community: community
        }
      ]),
      required_auths: [],
      required_posting_auths: [account]
    }
  ];

  return broadcastAll({
    author: account,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [join_community_operation],
    session
  });
}

export async function unsubscribeCommunity(community: string, account: string) {
  const leave_community_operation = [
    "custom_json",
    {
      id: "community",
      json: JSON.stringify([
        "unsubscribe",
        {
          community: community
        }
      ]),
      required_auths: [],
      required_posting_auths: [account]
    }
  ];

  return broadcastAll({
    author: account,
    hivesignerClient: hivesigner,
    type: KeychainKeyType.Posting,
    operations: [leave_community_operation]
  });
}
