import { FloatingPortal } from "@floating-ui/react";
import { faFileUpload, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SmallAvatar10 } from "../format/SmallAvatar";
import DisplayName from "../format/DisplayName";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "~/utils/cn";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";

export function LeoAuthLoginPopup({ leoAuth, setLeoAuth, switchingAccounts, onSubmit }: any) {
  useEffect(() => {
    if (leoAuth.visible) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.paddingRight = "17px";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0";
    }

    return () => {
      if (typeof window === "undefined") return;

      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0";
    };
  }, [leoAuth]);

  return (
    <FloatingPortal>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 w-screen h-screen p-4 bg-black/70 flex justify-center items-center z-1000"
        >
          <div className="relative flex flex-col p-5 gap-y-4 rounded-xl bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_16px_2px_rgb(255_255_255_/_10%)]">
            <button
              type="button"
              className="group/cancel absolute top-3 right-3 size-8 flex justify-center items-center rounded-full  hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150 cursor-pointer"
              onClick={() => setLeoAuth(prev => ({ ...prev, visible: false }))}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="sm"
                className="text-pri/50 dark:text-pri-d/50 grop-hover/cancel:text-pri dark:grop-hover/cancel:text-pri-d transition-colors duration-150"
                fixedWidth
              />
            </button>

            <div className="flex flex-col gap-y-1">
              <strong className="text-xl font-bold">Login with LeoAuth</strong>
              <small className="max-w-[420px] text-xs leading-snug text-[#86868b]">
                Since you're using LeoAuth for this account, you need to enter your private key to switch your account
                quickly.
              </small>
            </div>

            <div className="flex flex-row items-center gap-x-2 bg-c-subscriptions/15 border border-c-subscriptions/50 rounded-xl py-4 px-3">
              <SmallAvatar10 author={leoAuth.username} disableThreadcast disableThreadcastIndicator />

              <div className="flex flex-col gap-y-0.5">
                <DisplayName authorName={leoAuth.username} name={leoAuth.username} />
                <small className="text-xs text-[#86868b]">@{leoAuth.username}</small>
              </div>
            </div>

            <form
              className="flex flex-col justify-center items-center w-full gap-y-4"
              onSubmit={event => {
                event.preventDefault();
                onSubmit();
              }}
            >
              <div className="flex flex-col w-full gap-y-2">
                <label htmlFor="private-key" className="text-[#86868b] text-sm font-medium">
                  Posting Key
                </label>
                <input
                  type="text"
                  value={leoAuth.posting_key}
                  onChange={ev => setLeoAuth(prev => ({ ...prev, posting_key: ev.target.value }))}
                  className="w-full py-3 px-3 rounded-xl bg-pri-d/5 dark:bg-pri/5 border border-pri-d/50 text-sm font-medium outline-2 outline-offset-1 outline-transparent focus:outline-pri placeholder:text-pri-d/40 transition-all duration-150"
                  placeholder="Enter your private posting key"
                />
              </div>

              <button
                type="submit"
                className="peer flex justify-center items-center h-10 px-5 sm:min-w-[180px] gap-x-2 rounded-full text-sm font-semibold bg-pri text-pri cursor-pointer z-10 hover:bg-pri transition-all duration-300 opacity-100 hover:opacity-85 disabled:brightness-50 disabled:hover:opacity-100 disabled:cursor-not-allowed"
                disabled={!leoAuth.posting_key || switchingAccounts}
              >
                {switchingAccounts ? (
                  <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" />
                ) : (
                  "Login with LeoAuth"
                )}
              </button>
            </form>
          </div>
        </motion.div>
      </AnimatePresence>
    </FloatingPortal>
  );
}

export function KeystoreLoginPopup({ keystore, setKeystore, switchingAccounts, onSubmit }: any) {
  const isDarkMode = useAppStore(store => store.settings.dark);

  useEffect(() => {
    if (keystore.visible) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.paddingRight = "17px";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0";
    }

    return () => {
      if (typeof window === "undefined") return;

      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0";
    };
  }, [keystore]);

  function checkExtension(file: File, callback: Function) {
    if (file.type === "text/plain" || file.type === "application/json") {
      callback(file);
    } else {
      setKeystore(null);
      toast("Wrong file type! Please import a .txt or .json file.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3000
      });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      checkExtension(e.target.files[0], (file: File) => {
        setKeystore(prev => ({ ...prev, file }));
      });
    }
  }

  return (
    <FloatingPortal>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 w-screen h-screen p-4 bg-black/70 flex justify-center items-center z-1000"
        >
          <div className="relative flex flex-col p-5 gap-y-4 rounded-xl bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_16px_2px_rgb(255_255_255_/_10%)]">
            <button
              type="button"
              className="group/cancel absolute top-3 right-3 size-8 flex justify-center items-center rounded-full  hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150 cursor-pointer"
              onClick={() => setKeystore(prev => ({ ...prev, visible: false }))}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="sm"
                className="text-pri/50 dark:text-pri-d/50 grop-hover/cancel:text-pri dark:grop-hover/cancel:text-pri-d transition-colors duration-150"
                fixedWidth
              />
            </button>

            <div className="flex flex-col gap-y-1">
              <strong className="text-xl font-bold">Login with Keystore</strong>
              <small className="max-w-[420px] text-xs leading-snug text-[#86868b]">
                Since you're using Keystore for this account, you need to upload your keystore file and enter your
                decryption password to switch your account quickly.
              </small>
            </div>

            <div className="flex flex-row items-center gap-x-2 bg-c-subscriptions/15 border border-c-subscriptions/50 rounded-xl py-4 px-3">
              <SmallAvatar10 author={keystore.username} disableThreadcast disableThreadcastIndicator />

              <div className="flex flex-col gap-y-0.5">
                <DisplayName authorName={keystore.username} name={keystore.username} />
                <small className="text-xs text-[#86868b]">@{keystore.username}</small>
              </div>
            </div>

            <form
              className="flex flex-col justify-center items-center w-full gap-y-4"
              onSubmit={event => {
                event.preventDefault();
                onSubmit();
              }}
            >
              <div className="flex flex-col w-full gap-y-2">
                <label htmlFor="keystore-file" className="text-sm font-medium text-[#86868b]">
                  Keystore File
                </label>
                <label htmlFor="keystore-file" className="flex flex-col w-full gap-y-2">
                  <span
                    className={cn(
                      "flex flex-row justify-start items-center w-full h-16 gap-x-4 px-6 rounded-xl border-2 border-dashed border-pri-d/70 bg-black transition-all duration-150 text-[#86868b] cursor-pointer",
                      {
                        "hover:bg-[#101010]": !keystore.file,
                        "border-solid border-green-500 bg-green-500/10 text-green-500": keystore.file
                      }
                    )}
                  >
                    <FontAwesomeIcon icon={keystore.file ? faCheckCircle : faFileUpload} size="xl" />
                    <span className="text-sm font-medium">
                      {keystore.file ? (
                        <UploadedFilePreview
                          file={keystore.file}
                          removeFile={() => setKeystore(prev => ({ ...prev, file: null }))}
                        />
                      ) : (
                        "Click to select your keystore file"
                      )}
                    </span>
                  </span>
                </label>

                <input
                  id="keystore-file"
                  type="file"
                  multiple={false}
                  onChange={handleChange}
                  className="hidden"
                  hidden
                />
              </div>

              <div className="flex flex-col w-full gap-y-2">
                <label htmlFor="password" className="text-[#86868b] text-sm font-medium">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={keystore.posting_key}
                  onChange={ev => setKeystore(prev => ({ ...prev, password: ev.target.value }))}
                  className="w-full py-3 px-3 rounded-xl bg-pri-d/5 dark:bg-pri/5 border border-pri-d/50 text-sm font-medium outline-2 outline-offset-1 outline-transparent focus:outline-pri placeholder:text-pri-d/40 transition-all duration-150"
                  placeholder="•••••••••"
                />
              </div>

              <div className="relative flex flex-1 group sm:min-w-[180px] px-0.5">
                <button
                  type="submit"
                  className="peer flex flex-1 justify-center items-center h-10 px-5 gap-x-2 rounded-full text-sm font-semibold bg-pri text-pri cursor-pointer z-10 hover:bg-pri transition-all duration-300 opacity-100 hover:opacity-85 disabled:brightness-50 disabled:hover:opacity-100 disabled:cursor-not-allowed"
                  disabled={!keystore.file || !keystore.password || switchingAccounts}
                >
                  {switchingAccounts ? (
                    <FontAwesomeIcon icon={faSpinner} size="sm" className="animate-spin" />
                  ) : (
                    "Login with Keystore"
                  )}
                </button>

                <span className="absolute top-0 left-0 w-full h-10 rounded-full bg-gradient-to-r from-[#4ac9e7] via-[#8e55df] to-[#f49545] blur z-[1] transition-all duration-300 peer-disabled:opacity-50" />
              </div>
            </form>
          </div>
        </motion.div>
      </AnimatePresence>
    </FloatingPortal>
  );
}

function UploadedFilePreview(props: any) {
  return (
    <span
      className="duration group flex items-center gap-x-2 rounded-full border border-dashed border-green-500/50 bg-green-500/5 px-4 py-2 transition-colors hover:border-red-500 hover:bg-red-500/10"
      onClick={e => {
        e.preventDefault();
        props.removeFile();
      }}
    >
      <strong className="text-sm group-hover:text-red-500">{props.file.name}</strong>
      <FontAwesomeIcon icon={faTimes} size="sm" className="duration transition-colors group-hover:text-red-500" />
    </span>
  );
}

