import type { ActionFunction } from "@remix-run/node";
import { Link, useSearchParams, useSubmit } from "@remix-run/react";
import Logo from "~/components/Logo";
import LeoAuth from "~/components/auth/LeoAuth";
import { Divider, VerticalMarquee } from "~/components/auth/Utilities";
import { createAccountSession, destroyAccountSession } from "~/session.server";
import { cache } from "~/utils/cache";
import { verifySignToken } from "~/utils/verifysigntoken.server";
import { isSSR } from "~/utils/ssr";
import SocialAuth from "~/components/auth/SocialAuth";
import HiveLogin from "~/components/auth/HiveLogin";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppStore } from "~/store";

export const useGetRedirectTo = () => {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/threads";
  if (redirectTo === "/login" || redirectTo === "/signup") {
    return "/threads";
  }
  return redirectTo;
};

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();

  const message = formData.get("message") as string | null;
  const accountName = formData.get("accountName") as string | null;
  const type = formData.get("type") as string | null;
  let redirectTo = formData.get("redirectTo") as string;

  if (accountName === null || message === null) {
    return null;
  }

  const account = await cache.getAccount(accountName, true);

  if (type === null || type === "keychain") {
    let _message: { signature: string; phrase: string } | {} = {};

    try {
      _message = JSON.parse(message);
    } catch {
      console.log("Message is not JSON!");
    }

    switch (request.method) {
      case "POST":
      case "PUT": {
        if (verifySignToken(_message?.signature, account)) {
          return createAccountSession(accountName, redirectTo, "keychain", _message);
        } else {
          return null;
        }
      }
      case "PATCH": {
        return destroyAccountSession(request, "/login");
      }
      case "DELETE": {
        return destroyAccountSession(request, "/threads");
      }
    }
  } else if (type === "hivesigner") {
    switch (request.method) {
      case "POST": {
        return createAccountSession(accountName, redirectTo, "hivesigner", message);
      }
    }
  } else if (type === "leolock") {
    switch (request.method) {
      case "POST": {
        return createAccountSession(accountName, redirectTo, "leolock", message);
      }
    }
  }
};

// To hold user's other accounts
export const writeLocalStorageAccounts = (
  account: string,
  proxy: "keychain" | "hivesigner" | "leolock" | "keystore"
) => {
  if (isSSR()) return;

  let currentStorage;
  let _localStorage = localStorage.getItem("saved-accounts") || "[]";
  currentStorage = JSON.parse(_localStorage);

  if (currentStorage?.find(item => item[0] === account)) {
    const existing_account = currentStorage.find(item => item[0] === account);
    const old_account_removed = currentStorage.filter(x => x[0] !== existing_account[0]);

    if (old_account_removed) {
      localStorage.setItem("saved-accounts", JSON.stringify([[account, proxy], ...old_account_removed]));
    }

    return;
  }

  if (typeof currentStorage !== "object") {
    localStorage.setItem("saved-accounts", JSON.stringify([[account, proxy]]));
  } else {
    localStorage.setItem("saved-accounts", JSON.stringify([[account, proxy], ...currentStorage]));
  }
};

export const readLocalStorageAccounts = () => {
  if (isSSR()) return;

  let _localStorage = localStorage.getItem("saved-accounts") || "[]";
  const accounts = JSON.parse(_localStorage);

  if (accounts) {
    const seen = new Set();
    const result = accounts.filter(([name]: [string]) => {
      if (seen.has(name)) {
        return false; // Skip if the first character is already seen
      }
      seen.add(name);
      return true; // Keep if it's the first occurrence
    });

    return result;
  } else {
    return [];
  }
};

export default function Login() {
  const submit = useSubmit();
  const redirectTo = useGetRedirectTo();

  const [searchParams] = useSearchParams();
  const isDarkMode = useAppStore(store => store.settings.dark);

  const isHiveSigner = searchParams.get("state") === "leofinance";
  const access_token = searchParams.get("access_token");
  const username = searchParams.get("username");

  // hivesigner login request handler
  useEffect(() => {
    if (isHiveSigner === true) {
      if (!access_token || !username) {
        toast("There is something went wrong, please reload and try again.", {
          type: "error",
          autoClose: 3000,
          theme: isDarkMode ? "dark" : "light"
        });
        return;
      }

      try {
        const authDetails = JSON.parse(atob(access_token));
        const { signatures, signed_message } = authDetails;

        const signature = signatures?.[0] || "";
        window.localStorage.setItem("activeAccount", signature);
        window.localStorage.setItem("access_token", access_token);
      } catch {
        alert("Can't find any signature draft saving is disabled please relogin!");
      }
      submit(
        {
          message: access_token,
          accountName: username,
          type: "hivesigner",
          redirectTo
        },
        { method: "post", action: "/login" }
      );
    }
  }, [isHiveSigner]);

  return (
    <div className="flex flex-col flex-1 w-full h-full pt-16 pb-8 overflow-y-auto">
      {/* logo */}
      <Link to="/" className="fixed top-4 left-8 w-12 h-12">
        <Logo className="text-pri dark:text-pri-d origin-left scale-[3.525] pointer-events-none" noText />
      </Link>

      {/* vertical marquee */}
      <VerticalMarquee />

      <div className="flex flex-1 flex-col justify-center items-center sm:items-start pl-0 sm:pl-28 lg:pl-40">
        <div className="flex flex-col w-fit h-full max-w-full sm:max-w-[540px] px-4 gap-y-6">
          {/* heading */}
          <div className="flex flex-col mb-5">
            <h1 className="text-[clamp(38px,4vw,62px)] font-black !font-display uppercase leading-none whitespace-nowrap">
              join inleo
            </h1>
            <p className="text-[clamp(11.25px,1.185vw,18.375px)] font-black !font-display uppercase text-pri/40 dark:text-pri-d/40 leading-none whitespace-nowrap">
              microblogging on the blockchain
            </p>
          </div>

          {/* social section */}
          <SocialAuth />

          <Divider />

          {/* leoauth section */}
          <LeoAuth />

          <Divider />

          {/* hive section */}
          <HiveLogin />

          <div className="flex flex-col gap-y-2.5 mt-4">
            <strong className="font-semibold">Don't have an account?</strong>
            <Link
              to="/signup"
              role="button"
              title="Create Account"
              aria-label="Create Account"
              className="flex items-center justify-center w-fit py-2.5 px-8 rounded-full bg-acc text-pri-d text-sm font-medium hover:opacity-90 transition-opacity duration-150"
            >
              Create account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
