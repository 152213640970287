import { PrivateKey } from "@hiveio/dhive";
import { HiveAccountKeys } from "../hive";

export function generateKeys(
  username: string,
  password: string
): HiveAccountKeys {
  let opts = {
    addressPrefix: "STM",
    chainId: "beeab0de00000000000000000000000000000000000000000000000000000000"
  };

  const ownerKey = PrivateKey.fromLogin(username, password, "owner");
  const activeKey = PrivateKey.fromLogin(username, password, "active");
  const postingKey = PrivateKey.fromLogin(username, password, "posting");
  const memoKey = PrivateKey.fromLogin(username, password, "memo")
    .createPublic(opts.addressPrefix)
    .toString();

  const keys = {
    posting: postingKey.createPublic(opts.addressPrefix).toString(),
    active: activeKey.createPublic(opts.addressPrefix).toString(),
    ownerKey: ownerKey.createPublic(opts.addressPrefix).toString()
  };

  return {
    public: {
      ...keys,
      memo: memoKey
    },
    private: {
      ownerKey: ownerKey.toString(),
      activeKey: activeKey.toString(),
      postingKey: postingKey.toString()
    },
    memo: memoKey,
    password
  };
}
