import { FloatingPortal, useClick, useDismiss, useFloating, useInteractions, useRole } from "@floating-ui/react";
import React, { Fragment, Suspense, lazy, useCallback, useEffect, useState } from "react";
import { faArrowLeft, faBell, faSignOut, faSpinner, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";
import { AnimatePresence, motion } from "framer-motion";
import AccountManaBars from "./AccountManaBars";
import { SmallAvatar12, SmallAvatar6, SmallAvatar8 } from "./format/SmallAvatar";
import DisplayName from "./format/DisplayName";
import Reputation from "./format/Reputation";
import { FontAwesomeIcon, type FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate, useSubmit } from "@remix-run/react";
import { readLocalStorageAccounts, writeLocalStorageAccounts } from "~/routes/login";
import { cache } from "~/utils/cache";
import { createSignToken } from "~/utils/keychain";
import classNames from "classnames";
import Popover from "./Popover";
import { claimRewards } from "~/utils/transactions";
import { toast } from "react-toastify";
import type { ParsedAccount } from "~/utils/hive";
import { loadHivePrivateKey } from "~/utils/hive";
import useOnClickOutside from "~/hooks/useClickOutside";
import { useAppStore } from "~/store";
import { isSSR } from "~/utils/ssr";
import { decryptFromKeystore } from "@xchainjs/xchain-crypto";
import { generateKeys } from "~/routes/signup";
import * as hivesigner from "~/utils/hivesigner";
import { KeystoreLoginPopup, LeoAuthLoginPopup } from "./auth/SwitchAccountPopups";
import { cn } from "~/utils/cn";

const ProfileLink = lazy(() => import("~/components/ProfileLink"));

export default function AccountDropdown({
  children,
  loaderAccount: activeAccount
}: {
  children: React.ReactNode;
  loaderAccount: ParsedAccount;
}) {
  const isDarkMode = useAppStore(store => store.settings.dark);

  const claimedRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [accountData, setAccountData] = useState<any | undefined>(undefined);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [],
    placement: "top-start",
    strategy: "fixed"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);

  const submit = useSubmit();

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

  useOnRouteChange(() => {
    setOpen(false);
  });

  useOnClickOutside(claimedRef, () => setClaimed(false));

  const getAccountData = async () => {
    try {
      const account = await cache.getAccount(activeAccount?.name);
      setAccountData(account);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!activeAccount?.name) return;
    void (async function () {
      await getAccountData();
    })();
  }, [activeAccount?.name]);

  const hasRewardsToClaim = React.useMemo(() => {
    if (!accountData) return false;

    return (
      accountData.reward_hive_balance !== "0.000 HIVE" ||
      accountData.reward_hbd_balance !== "0.000 HBD" ||
      accountData.reward_vesting_balance !== "0.000000 VESTS"
    );
  }, [accountData]);

  //const hasRewardsToClaim = accountData ?( accountData.reward_hive_balance !== "0.000 HIVE" || accountData.reward_hbd_balance !== "0.000 HBD" || accountData.reward_vesting_balance !== "0.000000 VESTS") : false;

  const [claiming, setClaiming] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClaim = useCallback(async () => {
    if (!activeAccount || !accountData) {
      return toast("Something went wrong while claiming rewards, please try again later.", {
        type: "error",
        autoClose: 3000,
        theme: isDarkMode ? "dark" : "light"
      });
    }

    setClaiming(true);

    claimRewards(
      activeAccount?.name,
      accountData?.reward_hive_balance as any,
      accountData?.reward_hbd_balance as any,
      accountData?.reward_vesting_balance as any
    )
      .then(succ => {
        setClaimed(true);
      })
      .catch(async err => {
        toast.error("Something went wrong.");
      })
      .finally(async () => {
        setClaiming(false);
        await getAccountData();
      });
  }, [activeAccount, accountData]);

  const handleAccountLogout = (account: string) => {
    if (!account) return;

    // submit(
    //   { message: "Remove account session.", accountName: account },
    //   { method: "delete", action: "/login" }
    // );
    // const formData = new FormData();
    // formData.append("message", "Remove account session.");
    // formData.append("accountName", account);
    // formData.append("redirectTo", "/");

    localStorage.removeItem("activeAccount");
    localStorage.removeItem("access_token");

    submit(
      {
        message: "Remove account session.",
        accountName: account,
        redirectTo: "/"
      },
      { method: "DELETE", action: "/login" }
    );

    // fetch("/login", {
    //   method: "DELETE",
    //   body: formData
    // })
    //   .then(() => {
    //     localStorage.removeItem("activeAccount");
    //     localStorage.removeItem("access_token");
    //   })
    //   .finally(() => {
    //     window.location.reload();
    //   })
    //   .catch(err => {
    //     alert("Something went wrong.");
    //     console.error(err);
    //   });
  };

  const handleAddNewAccount = (account: string) => {
    if (!account) return;

    // submit(
    //   { message: "Remove account session.", accountName: account },
    //   { method: "patch", action: "/login" }
    // );
    // # TODO : We can add account without removing the current session.
    // const formData = new FormData();
    // formData.append("message", "Remove account session.");
    // formData.append("accountName", account);
    // formData.append("redirectTo", "/");
    // fetch("/login", {
    //   method: "PATCH",
    //   body: formData
    // }).then((res) => {
    //   console.log(res);
    // }).finally(() => {
    //   window.location.reload();
    // }).catch(err => {
    //   alert("Something went wrong.");
    //   console.error(err);
    // })
    //redirect to login
    // spa redirect to login
    navigate("/login?action=add-account&redirectTo=" + pathname);
  };

  if (activeAccount === null) return;

  return (
    <Fragment>
      <div ref={reference} {...getReferenceProps} onClick={() => setOpen(current => !current)}>
        {children}
      </div>

      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              ref={floating}
              tabIndex={-1}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1000
              }}
              {...getFloatingProps()}
            >
              <motion.div
                initial={{ opacity: 0, y: 5, scale: 0.98 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 5, scale: 0.98 }}
                transition={{ duration: 0.2 }}
                className="relative flex flex-col bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl pt-4 overflow-hidden drop-shadow-md min-w-[300px] sm:min-w-[340px] shadow-[0_0_12px_3px_rgb(255_255_255_/_20%)]"
              >
                <div className="flex items-center gap-x-3 pb-5 px-4 w-full">
                  <div className="flex flex-1 items-center justify-between w-full">
                    <div className="flex flex-1 items-center gap-x-3">
                      <div className="relative">
                        <SmallAvatar12 author={activeAccount?.name} />
                        <div className="absolute -bottom-1 -right-1">
                          <Reputation reputation={+activeAccount.reputation} />
                        </div>
                      </div>

                      <Suspense fallback={<></>}>
                        <ProfileLink accountName={activeAccount?.name} referrer={activeAccount?.name}>
                          <div className="flex flex-col mt-0.5">
                            <DisplayName
                              authorName={activeAccount?.name}
                              name={activeAccount.posting_json_metadata?.profile?.name || activeAccount?.name}
                            />
                            <small className="text-xs text-pri/60 dark:text-pri-d/60">@{activeAccount?.name}</small>
                          </div>
                        </ProfileLink>
                      </Suspense>
                    </div>

                    {hasRewardsToClaim && (
                      <button
                        id="claim-rewards-tooltip"
                        type="button"
                        title="Claim Rewards"
                        className={classNames(
                          "group relative flex justify-center items-center w-10 h-10 rounded-full transition-transform duration-150 hover:scale-110",
                          {
                            "opacity-50 pointer-events-none": claiming
                          }
                        )}
                        disabled={claiming}
                        onClick={() => handleClaim()}
                      >
                        <span className="absolute inset-0 w-full h-full bg-[radial-gradient(circle,_rgba(251,146,60,1)_0%,_rgba(251,146,60,0.1)_100%)] rounded-full blur opacity-50" />
                        <span
                          aria-live="polite"
                          className="group-hover:animate-none animate-bounce z-10 duration-1000 delay-300"
                        >
                          🎁
                        </span>
                        <span className="sr-only">Claim Rewards</span>

                        <Popover anchorId="claim-rewards-tooltip" content="Click to claim your rewards!" place="top" />
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex flex-1 items-center pb-3 px-3 border-b border-pri/50 dark:border-pri-d/50">
                  <AccountManaBars />
                </div>

                <div className="flex flex-col divide-y divide-solid divide-pri/50 dark:divide-pri-d/50">
                  <AccountSwitch />
                  <AccountDropdownItem
                    title="Add account"
                    icon={faUserPlus}
                    onClick={() => handleAddNewAccount(activeAccount?.name)}
                  />
                  <AccountDropdownItem
                    title={`Logout @${activeAccount?.name}`}
                    icon={faSignOut}
                    onClick={() => handleAccountLogout(activeAccount?.name)}
                  />
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>

      <FloatingPortal>
        <AnimatePresence>
          {claimed && (
            <motion.div
              initial={{ opacity: 0, scale: 0.97 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.97 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              className="fixed inset-0 flex justify-center items-center bg-black/40 z-[10001]"
            >
              <div
                ref={claimedRef}
                className="flex flex-col min-w-[360px] sm:min-w-[520px] py-5 px-6 gap-y-9 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
              >
                <div className="flex flex-col items-center w-full h-full pt-8 pb-6 px-12">
                  <div className="relative text-5xl flex justify-center items-center text-center w-32 h-32 rounded-full mb-4">
                    <span className="absolute inset-0 w-full h-full bg-[radial-gradient(circle,_rgba(34,197,94,1)_0%,_rgba(34,197,94,0.4)_100%)] rounded-full opacity-50 blur z-0" />
                    <span className="relative rotate-12 z-10">🎁</span>
                  </div>
                  <span className="text-2xl font-bold w-full text-center mt-2 mb-3">You've claimed your rewards!</span>

                  <div className="flex flex-col font-semibold divide-y divide-pri/50 dark:divide-pri-d/50 items-center justify-center w-full">
                    <div className="flex flex-row justify-between w-full py-4">
                      <div className="flex flex-row items-center">
                        <div className="flex items-center w-8 h-8 text-lg font-semibold rounded-full text-red-500 ">
                          H
                        </div>
                        <span className="text-base font-semibold pl-2 first-line:">HIVE</span>
                      </div>
                      <div className="font-bold text-lg text-green-500">
                        +{(accountData as any)?.reward_hive_balance?.split(" ")[0] || "0.000"}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full py-4">
                      <div className="flex flex-row items-center">
                        <div className="flex items-center w-8 h-8 text-lg font-semibold rounded-full text-green-500 ">
                          H
                        </div>
                        <span className="text-base font-semibold pl-2 first-line:">HBD</span>
                      </div>
                      <div className="font-bold text-lg text-green-500">
                        +{(accountData as any)?.reward_hbd_balance?.split(" ")[0] || "0.000"}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full py-4">
                      <div className="flex flex-row items-center">
                        <div className="flex items-center w-8 h-8 text-lg font-semibold rounded-full text-yellow-500 ">
                          V
                        </div>
                        <span className="text-base font-semibold pl-2 first-line:">VESTS</span>
                      </div>
                      <div className="font-bold text-lg text-green-500">
                        +{(accountData as any)?.reward_vests_balance?.split(" ")[0] || "0.000"}
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => setClaimed(false)}
                    className="active:scale-105 active:saturate-150 flex items-center justify-center py-3 px-5 mt-4 min-w-[140px] rounded-full bg-acc text-center text-pri font-semibold"
                  >
                    Great!
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </Fragment>
  );
}

function AccountDropdownItem({
  title,
  icon,
  onClick
}: {
  title: string;
  icon: FontAwesomeIconProps["icon"];
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className="flex items-center gap-x-3 w-full h-[52px] px-4 hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075]"
      onClick={() => onClick()}
    >
      <div className="flex ">
        <FontAwesomeIcon icon={icon} size="sm" fixedWidth />
      </div>

      <span className="text-sm font-medium leading-none">{title}</span>
    </button>
  );
}

function AccountSwitch() {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const submit = useSubmit();
  const { pathname } = useLocation();

  const [switchActive, setSwitchActive] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState<any>({});

  const [leoAuth, setLeoAuth] = useState({
    visible: false,
    username: "",
    posting_key: ""
  });

  const [keystore, setKeystore] = useState({
    visible: false,
    file: null,
    username: "",
    password: ""
  });

  const [switchingAccounts, setSwitchingAccounts] = useState<boolean>(false);

  const localStorageAccounts = React.useMemo(() => readLocalStorageAccounts() || [], []);

  // fetch account notifications
  useEffect(() => {
    let ntfs: any = {};

    localStorageAccounts.map(async (account: [string, string]) => {
      const notifications = await cache.getUnreadNotifications(account[0]);
      ntfs[account[0]] = notifications.unread;
    });

    setUnreadNotifications(ntfs);
  }, [localStorageAccounts]);

  if (!isSSR()) window.Buffer = window.Buffer || require("buffer").Buffer;

  const handleAccountSwitch = async (accountName: string, proxy: string) => {
    if (!accountName) {
      return;
    }

    try {
      switch (proxy) {
        case "keychain": {
          if (!window.hive_keychain) {
            hivesignerLogin();
            return;
          }

          setSwitchingAccounts(true);

          const message = await createSignToken(accountName);
          window.localStorage.setItem("activeAccount", message);
          writeLocalStorageAccounts(accountName, "keychain");
          submit(
            {
              message: JSON.stringify({
                signature: message
              }),
              accountName,
              type: "keychain",
              redirectTo: pathname
            },
            { method: "POST", action: "/login" }
          );

          setSwitchingAccounts(false);
          break;
        }
        case "leolock": {
          setLeoAuth(prev => ({ ...prev, username: accountName, visible: true }));
          break;
        }
        case "hivesigner": {
          hivesignerLogin();
          break;
        }
        case "keystore": {
          setKeystore(prev => ({ ...prev, username: accountName, visible: true }));
          break;
        }
        default:
          break;
      }
    } catch (err) {
      setSwitchingAccounts(false);

      console.error(err);
      toast(err.message || "Something went wrong", {
        type: "error",
        autoClose: 3000
      });
    }
  };

  const hivesignerLogin = useCallback(() => {
    setSwitchingAccounts(true);

    try {
      hivesigner.createSignToken();
    } catch (err) {
      setSwitchingAccounts(false);
      toast(err.message || "Something went wrong", {
        type: "error",
        autoClose: 3000
      });
    }

    setSwitchingAccounts(false);
  }, []);

  const leoAuthLogin = useCallback(async () => {
    if (typeof window === "undefined" || switchingAccounts || !leoAuth.username || !leoAuth.posting_key) return;

    setSwitchingAccounts(true);

    const loginString = Buffer.from(`{"login":"${leoAuth.username}"}`);
    const PrivateKey = await loadHivePrivateKey();
    let privateKey;

    try {
      privateKey = PrivateKey.fromString(leoAuth.posting_key);
    } catch (e: any) {
      setSwitchingAccounts(false);
      if (e.name == "AssertionError") {
        return toast(`Private Key is Invalid! Please ensure you use the correct "Posting Key" and "Username"`, {
          type: "error",
          theme: "light",
          autoClose: 3_000
        });
      }
      return toast(e.message, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }
    let publicKey;
    try {
      publicKey = privateKey.createPublic().toString();
    } catch {
      setSwitchingAccounts(false);
      return toast(`Posting key is malformed or wrong!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }

    const account = await cache.getAccount(leoAuth.username, true);
    if (account.posting.key_auths[0][0] !== publicKey) {
      setSwitchingAccounts(false);
      return toast(`Posting key doesn't match the ${leoAuth.username}'s posting key!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }

    window?.crypto?.subtle?.digest("SHA-256", loginString).then(digestedMessage => {
      const signature = privateKey.sign(Buffer.from(digestedMessage));
      window.localStorage.setItem("activeAccount", signature.toString());
    });

    writeLocalStorageAccounts(leoAuth.username, "leolock");

    setLeoAuth(prev => ({ ...prev, visible: false }));
    submit(
      {
        message: JSON.stringify({
          posting_key: leoAuth.posting_key
        }),
        accountName: leoAuth.username,
        type: "leolock",
        redirectTo: pathname
      },
      { method: "post", action: "/login" }
    );
  }, [leoAuth, pathname, submit, switchingAccounts]);

  const keystoreLogin = useCallback(async () => {
    if (
      typeof window === "undefined" ||
      switchingAccounts ||
      !keystore.username ||
      !keystore.password ||
      !keystore.file
    )
      return;
    setSwitchingAccounts(true);

    const _keystore = await (keystore.file as File).text();

    let wallet;
    try {
      wallet = await decryptFromKeystore(JSON.parse(_keystore), keystore.password);
    } catch {
      setSwitchingAccounts(false);
      return toast(`Password doesn't match the ${keystore.username}'s password!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }

    const leoAuth = generateKeys(keystore.username, wallet);

    const loginString = Buffer.from(`{"login":"${keystore.username}"}`);
    const PrivateKey = await loadHivePrivateKey();
    let privateKey;

    try {
      privateKey = PrivateKey.fromString(leoAuth.private.postingKey);
    } catch (e: any) {
      setSwitchingAccounts(false);
      if (e.name == "AssertionError") {
        return toast(`Private Key is Invalid! Please ensure you use the correct "Posting Key" and "Username"`, {
          type: "error",
          theme: "light",
          autoClose: 3_000
        });
      }
      return toast(e.message, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }
    let publicKey;
    try {
      publicKey = privateKey.createPublic().toString();
    } catch {
      setSwitchingAccounts(false);
      return toast(`Posting key is malformed or wrong!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }

    const account = await cache.getAccount(keystore.username, true);
    if (!account?.posting) {
      return toast(`Invalid username!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }
    if (account.posting.key_auths[0][0] !== publicKey) {
      setSwitchingAccounts(false);
      return toast(`Posting key doesn't match the ${keystore.username}'s posting key!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }

    window?.crypto?.subtle?.digest("SHA-256", loginString).then(digestedMessage => {
      const signature = privateKey.sign(Buffer.from(digestedMessage));
      window.localStorage.setItem("activeAccount", signature.toString());
    });

    writeLocalStorageAccounts(keystore.username, "keystore");

    setKeystore(prev => ({ ...prev, visible: false }));

    submit(
      {
        message: JSON.stringify({
          posting_key: leoAuth.private.postingKey,
          active_key: leoAuth.private.activeKey,
          phrase: wallet
        }),
        accountName: keystore.username,
        type: "leolock",
        redirectTo: pathname
      },
      { method: "post", action: "/login" }
    );

    setSwitchingAccounts(false);
  }, [keystore, pathname, submit, switchingAccounts]);

  const otherAccount = React.useMemo(() => {
    if (!activeAccount || !localStorageAccounts || localStorageAccounts?.length < 1) return;

    const other = localStorageAccounts.filter(([x]: string) => x !== activeAccount?.name)?.[0]?.[0];
    return other;
  }, [localStorageAccounts, activeAccount]);

  return (
    <Fragment>
      <span
        className={cn(
          "absolute inset-0 size-full bg-black opacity-0 transition-opacity duration-150 pointer-events-none z-10",
          {
            "opacity-40 pointer-events-auto": switchingAccounts
          }
        )}
      />

      <button
        type="button"
        className={classNames(
          "flex items-center gap-x-2.5 w-full h-[52px] pr-4 pl-3 hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075]",
          {
            "opacity-50 pointer-events-none": localStorageAccounts.length === 0
          }
        )}
        onClick={() => localStorageAccounts.length > 0 && setSwitchActive(true)}
      >
        <div className="relative flex items-center -space-x-1.5">
          <span className="relative flex w-6 h-6 rounded-full border border-bg dark:border-bg-d bg-gray-200 dark:bg-zinc-700 overflow-hidden">
            <SmallAvatar6 author={activeAccount?.name} disableThreadcast disableThreadcastIndicator />
          </span>
          {otherAccount ? (
            <span className="relative flex w-6 h-6 rounded-full border border-bg dark:border-bg-d bg-gray-200 dark:bg-zinc-700 overflow-hidden">
              <SmallAvatar6 author={otherAccount} disableThreadcast disableThreadcastIndicator />
            </span>
          ) : null}
        </div>

        <span className="text-sm font-medium leading-none">Switch Accounts ({localStorageAccounts.length})</span>
      </button>

      <AnimatePresence mode="wait">
        {switchActive ? (
          <motion.div
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="absolute inset-0 w-full h-full flex flex-col bg-pri dark:bg-pri-d rounded-xl overflow-hidden"
          >
            <div className="flex items-center gap-x-3 py-3 px-5 border-b border-pri dark:border-pri-d">
              <button
                type="button"
                aria-label="Back"
                className="flex justify-center items-center w-8 h-8 rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri-d/[.075]"
                onClick={() => setSwitchActive(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />
              </button>

              <strong className="font-semibold">Switch Accounts</strong>
            </div>

            <div className="flex flex-1 flex-col max-h-[calc(100%-57px)] divide-y divide-solid divide-pri/50 dark:divide-pri-d/50 overflow-y-auto overflow-x-hidden">
              {localStorageAccounts.map(([acc, proxy]: [string, string], index: number) => (
                <button
                  key={index}
                  type="button"
                  aria-label={acc}
                  className={classNames(
                    "flex items-center w-full gap-x-3 py-2 px-5 hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075]",
                    {
                      "opacity-50 pointer-events-none": activeAccount?.name === acc
                    }
                  )}
                  onClick={() => {
                    acc !== activeAccount?.name && handleAccountSwitch(acc, proxy);
                  }}
                >
                  <SmallAvatar8 author={acc} />

                  <div className="flex flex-col justify-start items-start mt-0.5">
                    <DisplayName authorName={acc} name={acc} className="text-sm" disableRedirect />
                    <small className="text-xs text-[#86868b] mt-0.5">@{acc}</small>
                  </div>

                  <span className="flex flex-row text-acc bg-acc/10 rounded-full py-0.5 px-1.5 ml-auto">
                    {unreadNotifications[acc] === undefined ? (
                      <div
                        className={`flex flex-1 flex-wrap gap-x-2 items-center justify-center content-center
                          h-fit text-sm opacity-50 cursor-not-allowed p-0.5`}
                      >
                        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                      </div>
                    ) : (
                      <div className="flex flex-row">
                        <div className="flex items-center justify-center gap-1">
                          <FontAwesomeIcon icon={faBell} size="xs" fixedWidth />
                          <span className="font-bold text-xxs">{unreadNotifications[acc]}</span>
                        </div>
                      </div>
                    )}
                  </span>
                </button>
              ))}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>

      {leoAuth.visible ? (
        <LeoAuthLoginPopup
          leoAuth={leoAuth}
          setLeoAuth={setLeoAuth}
          switchingAccounts={switchingAccounts}
          onSubmit={leoAuthLogin}
        />
      ) : null}

      {keystore.visible ? (
        <KeystoreLoginPopup
          keystore={keystore}
          setKeystore={setKeystore}
          switchingAccounts={switchingAccounts}
          onSubmit={keystoreLogin}
        />
      ) : null}
    </Fragment>
  );
}
