import React from "react";
import { faArrowLeftLong, faArrowRightLong, faArrowUpLong, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "~/utils/cn";

interface PriceTickerWrapperProps {
  children: React.ReactNode;
}

export function PriceTickerWrapper({ children }: PriceTickerWrapperProps) {
  const [scrollPos, setScrollPos] = React.useState<0 | 1>(0);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={cn("relative flex flex-col w-full pb-6 overflow-hidden transition-all duration-150", {
        "[mask-image:linear-gradient(to_left,transparent,black_30px,black_100%,transparent)]": scrollPos === 0,
        "[mask-image:linear-gradient(to_right,transparent,black_30px,black_100%,transparent)]": scrollPos > 0
      })}
    >
      <div
        ref={scrollerRef}
        className={cn(
          "flex items-stretch w-full h-full gap-3 whitespace-nowrap transition-transform duration-150 isolate",
          {
            "-translate-x-full": scrollPos > 0
          }
        )}
      >
        {children}
      </div>

      <div className="absolute right-6 -bottom-4 flex items-center justify-end z-10">
        <button
          className={cn(
            "flex items-center justify-center p-3 text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d transition-colors duration-150 disabled:opacity-50 disabled:pointer-events-none"
          )}
          onClick={() => setScrollPos(0)}
          disabled={scrollPos === 0}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>

        <button
          className={cn(
            "flex items-center justify-center p-3 text-pri/60 dark:text-pri-d/60 hover:text-pri dark:hover:text-pri-d transition-colors duration-150 disabled:opacity-50 disabled:pointer-events-none"
          )}
          onClick={() => setScrollPos(1)}
          disabled={scrollPos === 1}
        >
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
    </div>
  );
}

interface PriceTickerProps {
  token: string;
  price: number | undefined;
  change: number | undefined;
}

export function PriceTicker({ token, price, change }: PriceTickerProps) {
  if (price === undefined || change === undefined) return null;

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  });

  return (
    <div className="relative flex flex-col flex-1 p-4 pb-2 bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl sm:min-w-[33%]">
      <div className="flex flex-row items-center gap-3">
        <strong className="flex items-center gap-x-1.5 uppercase font-bold">
          <img src={`https://leodex.io/assets/${token.toLowerCase()}.webp`} width={24} height={24} alt={token} />
          <span>{token}</span>
        </strong>
        <span
          className={cn(
            "flex items-center gap-x-1.5 py-0 px-2 rounded-3xl bg-gray-500/[0.2] text-gray-500 !text-xs font-medium",
            { "bg-green-500/[0.2] text-green-500": change > 0 },
            { "bg-red-500/[0.2] text-red-500": change < 0 }
          )}
        >
          <FontAwesomeIcon
            icon={change === 0 ? faMinus : faArrowUpLong}
            size="sm"
            className={cn({ "rotate-45": change > 0, "rotate-[135deg]": change < 0 })}
          />
          {change > 0 ? `+${change.toFixed(2)}` : change.toFixed(2)}%
        </span>
      </div>

      <strong className="text-2xl font-extrabold mt-3 tabular-nums">${formatter.format(price)}</strong>

      <a
        href="https://coingecko.com"
        target="_blank"
        rel="noreferrer noopenner"
        className="text-xxs text-center text-zinc-500 mt-4 hover:underline"
      >
        Market value by CoinGecko
      </a>
    </div>
  );
}
