import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useMatches, Link } from "@remix-run/react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useAppStore } from "~/store";
import { cache } from "~/utils/cache";
import type { ParsedAccount, UnreadNotifications } from "~/utils/hive";
import AccountName from "./format/AccountName";
import DisplayName from "./format/DisplayName";
import { SmallAvatar10 } from "./format/SmallAvatar";

export interface NavItemContainerProps {
  children: React.ReactNode;
  className?: string;
}

export function NavItemContainer({ className, children }: NavItemContainerProps) {
  return (
    <div
      className={classNames(
        `
          flex items-center justify-center sm:justify-start 
          select-none gap-x-5 pc:py-[11px] text-gray-700 dark:text-gray-300 group-[.item-active]:text-pri dark:group-[.item-active]:text-pri-d
          cursor-pointer 
          pc:pl-4 pc:mr-4 pc:rounded-full hover:bg-pri-hov dark:hover:bg-pri-hov-d
        `,
        className
      )}
    >
      {children}
    </div>
  );
}

export interface NavItemProps {
  children: React.ReactNode;
  icon?: IconProp;
  className?: string;
}

export function NavItem({ className, children, icon }: NavItemProps) {
  return (
    <NavItemContainer className={className}>
      {icon && <FontAwesomeIcon icon={icon} size="xl" fixedWidth />}

      <span className="hidden sm:block text-xl group-[.item-active]:font-medium">{children}</span>
    </NavItemContainer>
  );
}

export interface NavLinkContainerProps {
  to: any;
  children: React.ReactNode;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export function NavLinkContainer({ to, children, target }: NavLinkContainerProps) {
  return (
    <NavLink
      target={target}
      prefetch="intent"
      className={({ isActive }) => (isActive ? "group item-active w-full" : "w-full")}
      to={to}
    >
      {children}
    </NavLink>
  );
}

export interface NavItemLinkProps {
  to: any;
  children?: React.ReactNode;
  icon?: IconProp;
  label?: string;
  className?: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

export function NavItemLink({ className, children, to, icon, target }: NavItemLinkProps) {
  return (
    <NavLinkContainer to={to} target={target}>
      <NavItem className={className} {...(icon && { icon })}>
        {children}
      </NavItem>
    </NavLinkContainer>
  );
}

export interface NavNotificationsProps {
  children: React.ReactNode;
  to: To;
  icon: IconProp;
  count: number;
}

export function NavNotifications({ children, to, icon, count }: NavNotificationsProps) {
  return (
    <NavLinkContainer to={to}>
      <NavItemContainer>
        <div className="relative">
          <FontAwesomeIcon icon={icon} size="xl" fixedWidth />
          {count > 0 && (
            <div className="absolute -top-[8px] left-[16px] min-w-[22px] max-w-[36px] h-[22px] px-1 text-xs flex justify-center items-center rounded-full text-pri font-bold bg-acc user-select border-2 border-[#fff] dark:border-[#181818]">
              {count > 99 ? "+99" : count}
            </div>
          )}
        </div>
        <span className="hidden pc:block text-xl">{children}</span>
      </NavItemContainer>
    </NavLinkContainer>
  );
}

export function NormalNavNotifications({ to, icon }: Omit<NavNotificationsProps, "children"> & { className?: string }) {
  const [unreadNotifications, setUnreadNotifications] = useState<UnreadNotifications | null>(null);
  const activeAccount = useAppStore(store => store.account.activeAccount);

  useEffect(() => {
    if (!activeAccount) return;

    void (async function () {
      const notifications = await cache.getUnreadNotifications(activeAccount?.name);
      setUnreadNotifications(notifications);
    })();
  }, [activeAccount]);
  return (
    <NavNotifications count={unreadNotifications?.unread || 0} icon={icon} to={to}>
      Notifications
    </NavNotifications>
  );
}

export function MinimizedNavNotifications({
  to,
  icon,
  className
}: Omit<NavNotificationsProps, "children"> & { className?: string }) {
  const [unreadNotifications, setUnreadNotifications] = useState<UnreadNotifications | null>(null);
  const activeAccount = useAppStore(store => store.account.activeAccount);

  useEffect(() => {
    if (!activeAccount) return;

    void (async function () {
      const notifications = await cache.getUnreadNotifications(activeAccount?.name);
      setUnreadNotifications(notifications);
    })();
  }, [activeAccount]);
  return (
    <NavLink
      title="Notifications"
      aria-label="Notifications"
      prefetch="intent"
      className={({ isActive }) =>
        classNames(
          "relative flex shrink-0 justify-center items-center size-10 sm:size-12 my-0.5 rounded-full hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150",
          {
            "text-gray-700 dark:text-gray-300": !isActive,
            "text-pri dark:text-pri-d": isActive
          },
          className
        )
      }
      to={to}
    >
      <FontAwesomeIcon icon={icon} size="lg" fixedWidth />
      {unreadNotifications?.unread > 0 && (
        <div className="absolute top-[3px] left-[20px] min-w-[22px] max-w-[36px] h-[22px] px-1 text-xs flex justify-center items-center rounded-full text-pri font-bold bg-acc user-select border-2 border-[#fff] dark:border-[#181818]">
          {unreadNotifications?.unread > 99 ? "+99" : unreadNotifications?.unread}
        </div>
      )}
    </NavLink>
  );
}

export function MinimizedItemLink({ className, to, icon, label, children }: NavItemLinkProps) {
  return (
    <NavLink
      title={label}
      aria-label={label}
      prefetch="intent"
      className={({ isActive }) =>
        classNames(
          "flex shrink-0 justify-center items-center size-10 sm:size-12 my-0.5 rounded-full hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150",
          {
            "text-gray-700 dark:text-gray-300": !isActive,
            "text-pri dark:text-pri-d": isActive
          },
          className
        )
      }
      to={to}
    >
      {icon && <FontAwesomeIcon icon={icon} size="lg" fixedWidth />}
      {children && children}
    </NavLink>
  );
}

export interface ActiveUserAccountProps {
  activeAccount: ParsedAccount | null;
}

export function ActiveUserAccount({ activeAccount }: ActiveUserAccountProps) {
  if (activeAccount === null) {
    return <LogInButton />;
  }

  return (
    <div className="flex flex-1 items-center justify-center pc:justify-start pc:pl-1.5 pc:pr-3 pc:gap-2">
      <SmallAvatar10 author={activeAccount?.name} disableThreadcast />
      <div className="hidden pc:flex flex-col gap-y-0.5 mt-[1px]">
        <DisplayName
          authorName={activeAccount?.name}
          name={activeAccount?.posting_json_metadata?.profile?.name || activeAccount?.name}
          disableRedirect
        />
        <AccountName author={activeAccount?.name} />
      </div>
      <div className="hidden pc:flex ml-auto flex-col items-center justify-center">
        <FontAwesomeIcon icon={faEllipsis} fixedWidth />
      </div>
    </div>
  );
}

export function MinimizedActiveUserAccount({ activeAccount }: ActiveUserAccountProps) {
  if (activeAccount === null) {
    return <LogInButton />;
  }

  return (
    <div className="flex w-12 h-12 items-center justify-center">
      <SmallAvatar10 author={activeAccount?.name} disableThreadcast />
    </div>
  );
}

export function LogInButton() {
  const routes = useMatches();
  const lastRoute = routes[routes?.length - 1];

  if (lastRoute.pathname === "/login") {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col gap-y-3 pc:flex-1 pc:mt-auto">
      <Link to="login" prefetch="intent" aria-label="Login Page" className="flex w-full justify-center">
        <div className="items-center justify-center gap-5 w-full h-12 hidden pc:flex p-3 bg-acc rounded-3xl transition-colors hover:bg-acc/[.85]">
          <span className="flex font-semibold text-pri">Log in</span>
        </div>
      </Link>
    </div>
  );
}
