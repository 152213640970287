import { isSSR } from "./ssr";

export function getCookie(name: string) {
  if (typeof document === "undefined") return undefined;

  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]?.trim();
    if (!cookie) continue;
    if (cookie?.startsWith(name + "=")) {
      let cookieData;
      try {
        cookieData = cookie.substring(name.length + 1).split(".")[0];
        if (name === "__session" && cookieData) {
          try {
            const session = JSON.parse(atob(decodeURIComponent(cookieData.split(".")[0])));
            if (!session) {
              return { auth: {}, proxy: "" };
            }

            return session;
          } catch (err) {
            console.error(err);
            return { auth: {}, proxy: "" };
          }
        }

        return JSON.parse(atob(decodeURIComponent(cookieData)));
      } catch (e) {
        if (!isSSR()) {
          if (!location) return { proxy: "", auth: {} };
          if (!location.pathname?.startsWith("/login") && !location.pathname?.startsWith("/register")) {
            location.replace("/login");
          }
          return { proxy: "", auth: {} };
        }
      }
    }
  }
}

export function getCookieWithoutRedirect(name: string) {
  if (typeof document === "undefined") return null;

  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function setCookie(name: string, value: string, days?: number, maxAge?: number) {
  if (typeof document === "undefined") return;

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
  } else if (maxAge) {
    document.cookie = `${name}=${value};max-age=${maxAge};path=/`;
  } else {
    document.cookie = `${name}=${value};path=/`;
  }
}
