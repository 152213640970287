import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, NavLink } from "@remix-run/react";
import classNames from "classnames";

import { useMediaQuery } from "~/hooks/useMediaQuery";
import Logo from "../Logo";
import {
  ActiveUserAccount,
  MinimizedActiveUserAccount,
  MinimizedItemLink,
  MinimizedNavNotifications,
  NavItemLink,
  NavNotifications,
  NormalNavNotifications
} from "../RootComponents";
import { useAppStore } from "~/store";
import type { ParsedAccount, UnreadNotifications } from "~/utils/hive";
import {
  faArrowRightToBracket,
  faEarth,
  faEllipsisH,
  faHouse,
  faPen,
  faSignIn,
  faVideoCamera,
  faWallet
} from "@fortawesome/free-solid-svg-icons";
import { faBell, faCompass, faNewspaper, faBookmark, faUser } from "@fortawesome/free-regular-svg-icons";
import MoreDropdown from "../MoreDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountDropdown from "../AccountDropdown";
import { SmallAvatar, SmallAvatar10, SmallAvatar8 } from "../format/SmallAvatar";
import { toast } from "react-toastify";
import { cn } from "~/utils/cn";
import PublishDropdownButton from "~/components/general/PublishDropdownButton";
import useScrollDirection from "~/hooks/useScrollDirection";
import { isSSR } from "~/utils/ssr";

export const Sidebar = React.memo(
  ({
    loaderAccount,
    isMobile: initialIsMobile,
    pathname
  }: {
    loaderAccount?: ParsedAccount | null;
    isMobile: boolean;
    pathname: string;
  }) => {
    const isDarkMode = useAppStore(store => store.settings.dark);
    const isLoggedIn = !!loaderAccount;

    const isTablet = useMediaQuery("(min-width: 640px) and (max-width: 1168px), (max-height: 679px)");

    const mobileQuery = useMediaQuery("(max-width: 600px)");
    const isMobile = isSSR() ? initialIsMobile : mobileQuery;

    // check localstorage value account-switched and show toast
    useEffect(() => {
      if (typeof window === "undefined") return;

      const accountSwitched = window.localStorage.getItem("account-switched");
      if (accountSwitched && loaderAccount) {
        window.localStorage.removeItem("account-switched");
        toast(`Successful switched to @${loaderAccount?.name}`, {
          type: "success",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3_000
        });
      }
    }, [loaderAccount]);

    const { publishRoute, postViewRoute, isPostReadingPage, referralRoute, dashboardRoute, walletRoute } =
      useMemo(() => {
        const isPublish = pathname.startsWith("/publish");
        const isPostView = pathname !== "/posts" && (pathname.startsWith("/posts") || pathname.startsWith("/@"));
        const isReadingPage = pathname.startsWith("/@");
        const isDashboard = pathname.startsWith("/dashboard") || pathname.startsWith("/keystore");
        const isReferral = pathname.startsWith("/referral");
        const isWallet = pathname.includes("/wallet");

        return {
          publishRoute: isPublish,
          postViewRoute: isPostView,
          isPostReadingPage: isReadingPage,
          dashboardRoute: isDashboard,
          referralRoute: isReferral,
          walletRoute: isWallet
        };
      }, [pathname]);

    const props = {
      loaderAccount,
      isLoggedIn,
      activeAccount: loaderAccount,
      isTablet,
      isMobile,
      publishRoute,
      postViewRoute,
      dashboardRoute,
      pathname
    };

    const forcedMinimized =
      publishRoute || dashboardRoute || isPostReadingPage || referralRoute || walletRoute || isTablet;

    return forcedMinimized ? <MinimizedSidebar {...props} /> : <DefaultSidebar {...props} />;
  }
);

Sidebar.displayName = "Sidebar";

type SidebarProps = {
  activeAccount: ParsedAccount | null;
  isTablet: boolean;
  isMobile: boolean;
  publishRoute: boolean;
  postViewRoute: boolean;
  isLoggedIn: boolean;
  dashboardRoute: boolean;
  loaderAccount: string;
  unreadNotifications: UnreadNotifications | null;
  pathname: string;
};

function MinimizedSidebar({ activeAccount, isMobile, dashboardRoute, isLoggedIn, pathname }: SidebarProps) {
  const scrollDirection = useScrollDirection(200);

  const communityNameForPublish = useMemo(() => {
    if (pathname.startsWith("/communities/")) {
      return pathname.split("/")[2];
    }
    return undefined;
  }, [pathname]);

  return (
    <aside className="w-screen fixed sm:w-[4.5rem] pc:relative bg-inherit !dark:bg-black sm:bg-inherit border-r border-pri dark:border-pri-d z-1000">
      <div
        className={cn(
          `
              dark:border-pri-d border-pri border-t sm:border-t-0
                sm:border-r lg:border-r-0
                sm:bg-inherit sm:backdrop-blur-none !z-20
                w-screen flex fixed bottom-0 py-0.5 pb-[env(safe-area-inset-bottom)] sm:py-4 px-2 sm:flex-col
                sm:sticky sm:h-screen sm:top-0 sm:ml-auto sm:w-full
                overflow-y-auto overflow-x-hidden scrollbar-none transition-all duration-150`,
          {
            "bg-pri dark:bg-pri-d": isMobile || !dashboardRoute,
            "translate-y-full": isMobile && scrollDirection === "down",
            "translate-y-0": isMobile && scrollDirection === "up"
          }
        )}
        style={{
          maskImage: !isMobile
            ? "linear-gradient(to top, transparent, black 20px, black calc(100% - 20px), transparent)"
            : undefined
        }}
      >
        <div className="hidden sm:flex items-center flex-col sm:flex-row">
          <Link
            prefetch="intent"
            aria-label="Threads Page"
            className="sm:flex shrink-0 w-40 py-2 pl-1.5 inline-flex items-center select-none"
            to="/threads"
          >
            <Logo noText />
          </Link>
          <Link className="w-14 h-14 p-3 block sm:hidden" aria-label="InLeo Home Page" prefetch="intent" to="/">
            <Logo />
          </Link>
        </div>
        <nav className="py-1 pl-0.5 gap-y-0.5 sm:mt-1 flex flex-row w-screen sm:w-full sm:flex-col justify-between z-10 text-xl sm:text-lg transition-transform duration-150">
          <MinimizedItemLink icon={faHouse} to="/threads" label="Threads" />
          <MinimizedItemLink icon={faCompass} to="/explore" label="Explore" />
          <MinimizedItemLink icon={faNewspaper} to="/posts" label="Posts" />
          <MinimizedItemLink icon={faVideoCamera} to="/shorts" label="Shorts" />
          <div className="hidden sm:flex">
            <MinimizedItemLink icon={faEarth} to="/communities" label="Communities" />
          </div>
          {isLoggedIn || (
            <div className="flex sm:hidden">
              <MinimizedItemLink icon={faArrowRightToBracket} to={`/login?redirectTo=${pathname}`} label="Login" />
            </div>
          )}
          {isLoggedIn && (
            <Fragment>
              <MinimizedNavNotifications icon={faBell} to="/notifications" />
              <div className="hidden sm:block sm:w-full">
                <MinimizedItemLink icon={faBookmark} to="/bookmarks" label="Bookmarks" />
              </div>
              <div className="hidden sm:block sm:w-full">
                <MinimizedItemLink to="/premium" className="max-h-[58px]" label="Premium">
                  <div className="flex items-center gap-x-[18px]">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="40" height="40">
                      <polygon
                        fill="currentColor"
                        points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
                      />
                      <polygon
                        fill="currentColor"
                        points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
                      />
                    </svg>
                  </div>
                </MinimizedItemLink>
              </div>

              <MinimizedItemLink
                icon={faWallet}
                to={`/${activeAccount?.name}/wallet`}
                label="Wallet"
                className="hidden sm:flex"
              />

              <MinimizedItemLink
                icon={faUser}
                to={`/profile/${activeAccount?.name}`}
                label="Profile"
                className="hidden sm:flex"
              />

              <NavLink
                to={`/profile/${activeAccount?.name}`}
                className="flex shrink-0 sm:hidden size-10 justify-center items-center"
              >
                {({ isActive }) => (
                  <div
                    className={classNames(
                      "flex size-9 rounded-full outline outline-2 outline-offset-1 outline-transparent transition-all duration-150",
                      {
                        "outline-pri-d dark:outline-pri": isActive
                      }
                    )}
                  >
                    <SmallAvatar size={9} author={activeAccount?.name} disableThreadcast />
                  </div>
                )}
              </NavLink>
            </Fragment>
          )}
          {isLoggedIn && (
            <Fragment>
              <div className="hidden sm:block sm:w-full">
                <MoreDropdown align="right">
                  <button
                    type="button"
                    title="More"
                    aria-label="More"
                    className="flex justify-center items-center w-12 h-12 my-0.5 rounded-full text-gray-700 dark:text-gray-300 hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150 cursor-pointer"
                  >
                    <FontAwesomeIcon icon={faEllipsisH} size="xl" fixedWidth />
                  </button>
                </MoreDropdown>
              </div>
              <div className="hidden rounded-full overflow-hidden sm:flex sm:justify-end w-12 sm:items-end sm:mt-2 pb-3">
                <Link
                  title="Publish"
                  aria-label="Publish"
                  prefetch="intent"
                  className="flex m-0 p-0 sm:mr-0 sm:w-11 sm:h-11 text-center justify-center items-center rounded-full text-pri font-semibold sm:hover:bg-acc/[.8] sm:bg-acc sm:dark:hover:bg-acc/[0.8] sm:dark:bg-acc"
                  to={`/publish${communityNameForPublish ? `?community=${communityNameForPublish}` : ""}`}
                >
                  <FontAwesomeIcon icon={faPen} fixedWidth />
                </Link>
              </div>
            </Fragment>
          )}
        </nav>
        <div className="relative flex justify-center items-center flex-col sm:mt-auto gap-y-2">
          <AccountDropdown loaderAccount={activeAccount}>
            <button
              className={classNames(
                "hidden sm:flex sm:justify-center sm:items-center w-12 h-12 sm:relative sm:mb-2 sm:py-2",
                {
                  "hover:bg-pri-hov dark:hover:bg-pri-hov-d sm:rounded-full": activeAccount
                }
              )}
              type="button"
            >
              <MinimizedActiveUserAccount activeAccount={activeAccount} />
            </button>
          </AccountDropdown>
        </div>
        {!isLoggedIn && !isMobile ? (
          <Link
            to={`/login?redirectTo=${pathname}`}
            role="button"
            className="flex justify-center items-center w-12 h-12 rounded-full bg-acc text-pri transition-opacity duration-150 hover:opacity-80 mx-auto"
          >
            <FontAwesomeIcon icon={faSignIn} />
          </Link>
        ) : null}
      </div>
    </aside>
  );
}

function DefaultSidebar({ isMobile, isLoggedIn, loaderAccount, pathname }: SidebarProps) {
  const communityNameForPublish = useMemo(() => {
    if (pathname.startsWith("/communities/")) {
      return pathname.split("/")[2];
    }
    return undefined;
  }, [pathname]);

  return (
    <aside className="w-screen absolute pc:w-80 pc:relative bg-inherit !dark:bg-black pc:bg-inherit !z-[999]">
      <div
        className="
                    bg-pri dark:bg-pri-d dark:border-pri-d border-pri border-t pc:border-none
                    overflow-hidden
                    pc:bg-inherit pc:backdrop-blur-none !z-20
                    w-screen
                    flex fixed bottom-0 p-4 pc:flex-col pc:sticky pc:h-screen pc:top-0 pc:ml-auto pc:w-full"
      >
        <div className="hidden pc:flex items-center flex-col pc:flex-row">
          <Link
            prefetch="intent"
            aria-label="Threads Page"
            className="pc:block w-40 pl-[14px] py-2 inline-flex items-center select-none"
            to="/threads"
          >
            <Logo />
          </Link>
          <Link className="w-14 h-14 p-3 block pc:hidden" aria-label="InLeo Home Page" prefetch="intent" to="/">
            <Logo />
          </Link>
          <div className="ml-auto"></div>
        </div>
        <nav
          className={cn(
            "p-1 gap-y-0.5 pl-0 pc:mt-1 flex flex-row w-screen pc:w-full pc:flex-col justify-between z-10 pc:text-lg",
            {
              "[padding-bottom:env(safe-area-inset-bottom,4px)]": isMobile
            }
          )}
        >
          <NavItemLink icon={faHouse} to="/threads">
            Home
          </NavItemLink>
          <NavItemLink icon={faCompass} to="/explore">
            Explore
          </NavItemLink>
          <NavItemLink icon={faNewspaper} to="/posts">
            Articles
          </NavItemLink>
          <NavItemLink icon={faVideoCamera} to="/shorts">
            Shorts
          </NavItemLink>
          <div className="hidden pc:block pc:w-full">
            <NavItemLink icon={faEarth} to="/communities">
              Communities
            </NavItemLink>
          </div>
          {isLoggedIn || (
            <div className="block sm:hidden w-full">
              <NavItemLink icon={faArrowRightToBracket} to={`/login?redirectTo=${pathname}`}>
                Login
              </NavItemLink>
            </div>
          )}
          {isLoggedIn && (
            <Fragment>
              <NormalNavNotifications icon={faBell} to="/notifications">
                Notifications
              </NormalNavNotifications>
              <div className="hidden pc:block pc:w-full">
                <NavItemLink icon={faBookmark} to="/bookmarks">
                  Bookmarks
                </NavItemLink>
              </div>
              <div className="hidden pc:block pc:w-full">
                <NavItemLink to="/premium" className="max-h-[58px]">
                  <div className="flex items-center gap-x-[18px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-[24px] pr-[4px]"
                      viewBox="165.67 289.64 668.72 500.72"
                    >
                      {" "}
                      <polygon
                        fill="currentColor"
                        points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
                      ></polygon>{" "}
                      <polygon
                        fill="currentColor"
                        points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
                      ></polygon>{" "}
                    </svg>
                    <span className="flex flex-1">Premium</span>
                  </div>
                </NavItemLink>
              </div>

              <div className="hidden sm:flex w-full">
                <NavItemLink
                  className="md:rounded-md sm:rounded-md"
                  icon={faWallet}
                  to={`/${loaderAccount?.name}/wallet`}
                >
                  Wallet
                </NavItemLink>
              </div>

              <div className="hidden sm:flex w-full">
                <NavItemLink
                  className="md:rounded-md sm:rounded-md"
                  icon={faUser}
                  to={`/profile/${loaderAccount?.name}`}
                >
                  Profile
                </NavItemLink>
              </div>

              <div className="sm:hidden w-full">
                <NavLink
                  to={`/profile/${loaderAccount?.name}`}
                  className="flex flex-1 justify-center items-center -mt-1.5"
                >
                  {({ isActive }) => (
                    <div
                      className={classNames(
                        "flex w-10 h-10 rounded-full outline outline-2 outline-offset-1 outline-transparent transition-all duration-150",
                        {
                          "outline-pri-d dark:outline-pri": isActive
                        }
                      )}
                    >
                      <SmallAvatar10 author={loaderAccount?.name} disableThreadcast />
                    </div>
                  )}
                </NavLink>
              </div>
            </Fragment>
          )}
          {isLoggedIn && (
            <Fragment>
              <div className="hidden pc:block pc:w-full">
                <MoreDropdown>
                  <div className="flex items-center justify-center pc:justify-start select-none gap-x-5 pc:py-[15px] text-gray-700 dark:text-gray-300 group-[.item-active]:text-pri dark:group-[.item-active]:text-pri-d cursor-pointer pc:pl-4 pc:mr-4 pc:rounded-full hover:bg-pri-hov dark:hover:bg-pri-hov-d">
                    <FontAwesomeIcon icon={faEllipsisH} size="xl" fixedWidth />
                    More
                  </div>
                </MoreDropdown>
              </div>
              {/*<Link*/}
              {/*  title="Publish"*/}
              {/*  aria-label="Publish"*/}
              {/*  prefetch="render"*/}
              {/*  className="flex flex-1 m-0 p-0 pc:mr-0 pc:px-4 pc:py-3 text-center justify-center pc:justify-center rounded-3xl text-pri font-medium pc:hover:bg-acc/[.85] pc:bg-acc pc:dark:hover:bg-acc/[0.85] pc:dark:bg-acc"*/}
              {/*  to={`/publish${*/}
              {/*    communityNameForPublish*/}
              {/*      ? `?community=${communityNameForPublish}`*/}
              {/*      : ""*/}
              {/*  }`}*/}
              {/*>*/}
              {/*  <span className="hidden pc:flex text-[17px]">Publish</span>*/}
              {/*</Link>*/}
              <PublishDropdownButton communityNameForPublish={communityNameForPublish} />
            </Fragment>
          )}
        </nav>
        <div className="relative flex flex-col pc:mt-auto pc:pr-8 gap-y-2">
          <AccountDropdown loaderAccount={loaderAccount}>
            <button
              className={classNames("hidden pc:flex items-center pc:w-full pc:relative pc:mb-2 pc:py-2 pc:pl-1", {
                "hover:bg-pri-hov dark:hover:bg-pri-hov-d pc:rounded-full": isLoggedIn
              })}
              type="button"
            >
              <ActiveUserAccount activeAccount={loaderAccount} />
            </button>
          </AccountDropdown>
        </div>
        {!isLoggedIn && (
          <Link
            to={`/login?redirectTo=${pathname}`}
            role="button"
            className="hidden sm:flex justify-center items-center w-full max-w-[90%] mt-auto gap-x-3 py-2.5 px-5 rounded-full bg-acc text-pri font-medium transition-opacity duration-150 hover:opacity-80 mx-auto"
          >
            <FontAwesomeIcon icon={faSignIn} />
            <span>Login</span>
          </Link>
        )}
      </div>
    </aside>
  );
}
