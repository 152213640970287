import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "./useDebounce";
import { fetchAccounts } from "~/utils/hive";

export default function useAccountNameValid(username: string, delay?: number) {
  const debouncedUsername = useDebounce(username, delay || 250);

  const [usernameExist, setUsernameExist] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (debouncedUsername.length < 3) return;

    void (async function () {
      setIsFetching(true);
      try {
        const account = await fetchAccounts([debouncedUsername]);
        if (account[0]?.memo_key !== "STM8kXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX") {
          setUsernameExist(true);
        } else {
          setUsernameExist(false);
        }
        setIsFetching(false);
      } catch {
        setUsernameExist(false);
        setIsFetching(false);
      }
    })();
  }, [debouncedUsername]);

  const checkAccountNameLength = useMemo(
    () => debouncedUsername.length < 3 || debouncedUsername.length > 16,
    [debouncedUsername]
  );
  const isInvalid = useMemo(() => {
    const exp = /^[a-z0-9.-]+$/;
    return debouncedUsername.length > 0 && (checkAccountNameLength || usernameExist || !exp.test(debouncedUsername));
  }, [debouncedUsername, checkAccountNameLength, usernameExist]);

  const isValid = useMemo(() => debouncedUsername.length > 0 && !isInvalid, [debouncedUsername, isInvalid]);

  return [isValid, isInvalid, isFetching];
}
