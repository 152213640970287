import * as React from "react";
import type { PollOptions } from "~/components/format/Polls";
import { lazy, useEffect, useMemo, useState } from "react";
import { useAppStore } from "~/store";
import { useSlate } from "slate-react";
import { Node, Transforms } from "slate";
import useOnClickOutside from "~/hooks/useClickOutside";
import { initialValue } from "~/components/MarkdownEditor/consts";
import { threadSlugify } from "~/utils/slugify";
import { UnvotedPollView } from "~/components/format/UnvotedPollView";
import classNames from "classnames";
import FormatButton from "~/components/MarkdownEditor/buttons/FormatButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faChevronLeft,
  faChevronRight,
  faFaceSmile,
  faHeading,
  faImage,
  faItalic,
  faListUl,
  faPoll,
  faQuoteLeft,
  faVideo
} from "@fortawesome/free-solid-svg-icons";
import { FloatingPortal } from "@floating-ui/react";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import Picker from "@emoji-mart/react";
import PreviewToggleButton from "~/components/MarkdownEditor/buttons/PreviewToggleButton";
import CharacterLimitIndicator from "~/components/MarkdownEditor/CharacterLimitIndicator";
import SelectCommunityButton from "~/components/MarkdownEditor/buttons/SelectCommunityButton";
import SubmitButton from "~/components/MarkdownEditor/buttons/SubmitButton";
import UploadShorts from "~/components/UploadShorts";
import TenorGifPicker from "~/components/TenorGifPicker";
import PublishChooseCommunities from "~/components/publish/ChooseCommunities";

import { MarkdownEditorTypes } from "~/components/MarkdownEditor";
// @ts-ignore
import { ClientOnly } from "remix-utils/client-only";
import { Preview } from "~/components/MarkdownEditor/Preview";
import { cn } from "~/utils/cn";
interface EditorFooterProps {
  togglePreview: boolean;
  setTogglePreview: React.Dispatch<React.SetStateAction<boolean>>;
  handlePost: (body: string, pollOptions: object, dimensions: any) => Promise<void>;
  type: MarkdownEditorTypes;
  setPollOptions: React.Dispatch<React.SetStateAction<PollOptions>>;
  pollsOpen: boolean;
  pollOptions: PollOptions;
  isPollCreated: boolean;
  removePoll: () => void;
  setIsPollCreated: React.Dispatch<React.SetStateAction<boolean>>;
  dimensions: any;
  handleBold: (ev: React.MouseEvent) => void;
  handleItalic: (ev: React.MouseEvent) => void;
  handleHeading: (ev: React.MouseEvent) => void;
  handleImage: (ev: React.MouseEvent) => void;
  handleBlockquote: (ev: React.MouseEvent) => void;
  handleUnorderedList: (ev: React.MouseEvent) => void;
  setPollsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  insertImage: (imageUrl: string) => void;
  threadSubmitRef?: React.RefObject<HTMLButtonElement>;
}

export const EditorFooter = React.memo(function EditorFooter({
  togglePreview,
  setTogglePreview,
  handlePost,
  encrypted,
  setPollOptions,
  pollOptions,
  type,
  dimensions,
  handleBold,
  handleItalic,
  handleHeading,
  handleImage,
  handleBlockquote,
  handleUnorderedList,
  setPollsOpen,
  isPollCreated,
  removePoll,
  setIsPollCreated,
  insertImage,
  threadSubmitRef
}: EditorFooterProps) {
  const settings = useAppStore(store => store.settings);
  const premiumState = useAppStore(store => store.account.premium);
  const isDarkMode = useAppStore(store => store.settings.dark);

  const THREADS_LIMIT = useMemo(() => (premiumState.is_premium ? 1000 : 240), [premiumState]);

  const editor = useSlate();

  const emojiPickerRef = React.useRef<HTMLDivElement>(null);
  const emojiPickerModalRef = React.useRef<HTMLDivElement>(null);

  const [gifsActive, setGifsActive] = React.useState<boolean>(false);
  const [emojisActive, setEmojisActive] = React.useState<boolean>(false);
  const [lock, setLock] = useState(false);
  const [posted, setPosted] = useState(false);
  const [uploadShorts, setUploadShorts] = useState(false);
  const [uploadedShortVideo, setUploadedShortVideo] = useState<File | null>(null);

  const actionScroller = React.useRef<HTMLDivElement>(null);
  const actionScrollerContainer = React.useRef<HTMLDivElement>(null);
  const [showScrollIndicator, setShowScrollIndicator] = React.useState<boolean>(false);
  const [overflowScroll, setOverflowScroll] = React.useState<0 | 1>(0);

  const content = editor.children.map(n => Node.string(n)).join("\n");
  useOnClickOutside(emojiPickerModalRef, () => setEmojisActive(false));
  const disabled =
    lock || content?.length === 0 || content?.length > (type === MarkdownEditorTypes.Thread ? THREADS_LIMIT : Infinity);

  const handleClick = async (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (disabled) {
      return;
    }
    setLock(true);

    const isPollThread = isPollCreated;
    setIsPollCreated(false);

    const isShortsVideo = content.includes("https://3speak.tv/watch?v=leoshorts");

    try {
      await handlePost(content, isPollThread ? pollOptions : undefined, dimensions);

      setPosted(true);

      setTimeout(() => setPosted(false), 1500);

      const children = [...editor.children];
      children.forEach(node => editor.apply({ type: "remove_node", path: [0], node }));

      Transforms.insertNodes(editor, initialValue);
      setLock(false);
    } catch (err) {
      console.error(err);
      setLock(false);
    }
  };

  const handleGifWindow = async ev => {
    ev.preventDefault();
    setEmojisActive(false);
    setGifsActive(currentState => !currentState);
  };

  const handleGifClick = gifPickEvent => {
    insertImage(gifPickEvent.url);
    setGifsActive(false);
  };

  const handleEmojiWindow = async ev => {
    ev.preventDefault();
    setGifsActive(false);
    setEmojisActive(currentState => !currentState);
  };

  const handleEmojiClick = emojiPickEvent => {
    setGifsActive(false);
    setEmojisActive(false);
    insertImage(emojiPickEvent.native);
  };

  const handleUpload = (file: File) => {
    setUploadedShortVideo(file);
  };

  const progressAsPercentage = Math.min((content?.length / THREADS_LIMIT) * 100, 100);

  const remainingLimit = THREADS_LIMIT - content?.length;

  const [publishChooseCommunities, setPublishChooseCommunities] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState([]);

  useEffect(() => {
    selectedCommunity.at(0) && insertImage(`#${threadSlugify(selectedCommunity.at(0)?.label)}`);
  }, [selectedCommunity]);

  useEffect(() => {
    if (!actionScrollerContainer.current || !actionScroller.current) return;

    const container_width = actionScrollerContainer.current.getBoundingClientRect().width;
    const inner_width = actionScroller.current.getBoundingClientRect().width;
    const difference = Math.abs(container_width - inner_width);

    if (inner_width > container_width) {
      setShowScrollIndicator(true);
    }
  }, []);

  return (
    <React.Fragment>
      {togglePreview && (
        <Preview
          pollOptions={pollOptions}
          isPollCreated={isPollCreated}
          editPoll={() => setPollsOpen(true)}
          removePoll={() => removePoll()}
        />
      )}
      <UnvotedPollView pollOptions={pollOptions} />
      <div className="flex flex-1 justify-between items-center p-2 gap-x-3">
        <div
          ref={actionScrollerContainer}
          className={cn(
            "relative flex flex-1 h-[30px] items-center shrink-0 text-acc w-full sm:max-w-[100%] z-10 overflow-hidden",
            {
              "text-c-subscriptions": encrypted
            }
          )}
        >
          <div
            ref={actionScroller}
            className={classNames(
              "absolute h-[30px] flex items-center flex-nowrap whitespace-nowrap tansition-transform duration-150",
              {
                "left-0": overflowScroll === 0,
                "right-0": overflowScroll === 1
              }
            )}
          >
            <FormatButton handleClick={handleBold} label="Bold" requirePremium>
              <FontAwesomeIcon icon={faBold} className="w-5 h-5" />
            </FormatButton>
            <FormatButton handleClick={handleItalic} label="Italic" requirePremium>
              <FontAwesomeIcon icon={faItalic} className="w-5 h-5" />
            </FormatButton>
            <FormatButton handleClick={handleHeading} label="Heading" requirePremium>
              <FontAwesomeIcon icon={faHeading} className="w-5 h-5" />
            </FormatButton>
            <FormatButton handleClick={handleBlockquote} label="Blockquote" requirePremium>
              <FontAwesomeIcon icon={faQuoteLeft} className="w-5 h-5" />
            </FormatButton>
            <FormatButton handleClick={handleUnorderedList} label="Unordered List" requirePremium>
              <FontAwesomeIcon icon={faListUl} className="w-5 h-5" />
            </FormatButton>
            {type === MarkdownEditorTypes.Thread && (
              <FormatButton handleClick={() => setUploadShorts(true)} label="Upload Short">
                <FontAwesomeIcon icon={faVideo} className="w-5 h-5" />
              </FormatButton>
            )}
            <FormatButton handleClick={handleImage} label="Upload Image">
              <FontAwesomeIcon icon={faImage} className="w-5 h-5" />
            </FormatButton>

            {type === MarkdownEditorTypes.Thread && (
              <FormatButton handleClick={() => setPollsOpen(true)} label="Add Poll">
                <FontAwesomeIcon icon={faPoll} className="w-5 h-5" />
              </FormatButton>
            )}

            <ClientOnly>
              {() => (
                <React.Fragment>
                  <div ref={emojiPickerRef} className="relative flex">
                    <FormatButton handleClick={handleEmojiWindow} label="Emoji">
                      <FontAwesomeIcon icon={faFaceSmile} className="w-5 h-5" />
                    </FormatButton>
                    <FloatingPortal>
                      {emojisActive && (
                        <FloatingHTMLOverlay className="z-[1002]">
                          <div className="relative">
                            <div
                              ref={emojiPickerModalRef}
                              tabIndex={0}
                              className="absolute max-h-92 w-[260px] tbl:w-[310px] z-50 mt-10 -translate-x-[50%]"
                              onClick={ev => {
                                ev.preventDefault();
                                ev.stopPropagation();
                              }}
                              style={{
                                left:
                                  overflowScroll === 1 ? "50%" : emojiPickerRef.current?.getBoundingClientRect()?.left,
                                top: emojiPickerRef.current?.getBoundingClientRect()?.top
                              }}
                            >
                              <Picker
                                data={async function () {
                                  const response = await fetch("https://cdn.jsdelivr.net/npm/@emoji-mart/data");

                                  return response.json();
                                }}
                                skinTonePosition="none"
                                theme={isDarkMode ? "dark" : "light"}
                                onEmojiSelect={handleEmojiClick}
                                dynamicWidth={true}
                                icons="solid"
                              />
                            </div>
                          </div>
                        </FloatingHTMLOverlay>
                      )}
                    </FloatingPortal>
                  </div>

                  <div className="relative flex">
                    <FormatButton handleClick={handleGifWindow} label="GIF">
                      <span className="font-bold text-lg sm:text-base">GIF</span>
                    </FormatButton>
                  </div>
                </React.Fragment>
              )}
            </ClientOnly>
          </div>

          {showScrollIndicator ? (
            overflowScroll === 0 ? (
              <button
                type="button"
                title="Next"
                aria-label="Next"
                className="absolute right-0 text-base flex justify-center items-center w-8 h-8 bg-pri dark:bg-pri-d text-acc z-10"
                onClick={() => setOverflowScroll(1)}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            ) : (
              <button
                type="button"
                title="Prev"
                aria-label="Prev"
                className="absolute left-0 text-base flex justify-center items-center w-8 h-8 bg-pri dark:bg-pri-d text-acc z-10"
                onClick={() => setOverflowScroll(0)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            )
          ) : null}
        </div>

        <div className="flex items-center gap-2 justify-end select-none">
          {content.length && !MarkdownEditorTypes[type] === (MarkdownEditorTypes.Post as string) ? (
            <PreviewToggleButton togglePreview={togglePreview} setTogglePreview={setTogglePreview} />
          ) : null}

          {type !== MarkdownEditorTypes.Comment && (
            <CharacterLimitIndicator
              settings={settings}
              contentLength={content.length}
              THREADS_LIMIT={THREADS_LIMIT}
              remainingLimit={remainingLimit}
              isDarkMode={isDarkMode}
              progressAsPercentage={progressAsPercentage}
            />
          )}

          {type === MarkdownEditorTypes.Thread && (
            <SelectCommunityButton
              selectedCommunity={selectedCommunity}
              setPublishChooseCommunities={setPublishChooseCommunities}
            />
          )}
          <SubmitButton
            MarkdownEditorTypes={MarkdownEditorTypes}
            handleClick={handleClick}
            type={type}
            encrypted={encrypted}
            disabled={disabled}
            posted={posted}
            loading={lock}
            ref={threadSubmitRef}
          />
        </div>

        {type !== MarkdownEditorTypes.Comment && (
          <ClientOnly>
            {() => (
              <React.Suspense fallback={<></>}>
                <PublishChooseCommunities
                  visibility={publishChooseCommunities}
                  setVisibility={setPublishChooseCommunities}
                  tags={selectedCommunity}
                  setTags={setSelectedCommunity}
                />
              </React.Suspense>
            )}
          </ClientOnly>
        )}

        <ClientOnly>
          {() => (
            <UploadShorts
              visibility={uploadShorts}
              setVisibility={setUploadShorts}
              onUpload={handleUpload}
              insertText={insertImage}
            />
          )}
        </ClientOnly>

        <FloatingPortal>
          {gifsActive && <TenorGifPicker key="thread" handleGifClick={handleGifClick} setGifsActive={setGifsActive} />}
        </FloatingPortal>
      </div>
    </React.Fragment>
  );
});

