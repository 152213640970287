import { FloatingPortal } from "@floating-ui/react";
import { faCheckCircle, faFileUpload, faSpinner, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyMotion, m } from "framer-motion";
import { useEffect, useRef } from "react";
import useOnClickOutside from "~/hooks/useClickOutside";
import HIVE from "~/images/hive.svg";
import { HiveKeychainKeys } from "../HiveLogos";
import { toast } from "react-toastify";
import keystore, { UploadedFilePreview } from "~/routes/keystore";
import { cn } from "~/utils/cn";

const loadFeatures = () => import("~/components/framermotion/features").then(res => res.default);

interface KeychainAccountModalProps {
  visibility: boolean;
  username: string;
  loading: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  keystoreProps: {
    setKeystore: React.Dispatch<React.SetStateAction<any>>;
    setKeystorePass: React.Dispatch<React.SetStateAction<string>>;
    keystore: File | undefined;
    keystorePass: string;
  };
  setUsername: (username: string) => void;
  onSubmit: () => void;
}

export default function KeychainAccountModal({
  visibility,
  username,
  loading,
  keystoreProps,
  setVisibility,
  setUsername,
  onSubmit
}: KeychainAccountModalProps) {
  const modalInnerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(modalInnerRef, () => setVisibility(false));

  function checkExtension(file: File, callback: Function) {
    if (file.type === "text/plain" || file.type === "application/json") {
      callback(file);
    } else {
      keystoreProps.setKeystore(null);
      toast("Wrong file type! Please import a .txt or .json file.", {
        type: "error",
        theme: "dark",
        autoClose: 3000
      });
    }
  }

  // upload manually (by click)
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      checkExtension(e.target.files[0], (file: File) => {
        keystoreProps.setKeystore(file);
      });
    } else {
      keystoreProps.setKeystore(null);
    }
  }

  function removeKeystore() {
    keystoreProps.setKeystore(null);
  }

  // cleanup username on close
  useEffect(() => {
    if (!visibility) {
      setUsername("");
    }
  }, [visibility]);

  return (
    <FloatingPortal>
      <LazyMotion features={loadFeatures}>
        {visibility && (
          <m.div
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            className="fixed inset-0 flex justify-center items-center bg-black/30 z-[10001]"
          >
            <div
              ref={modalInnerRef}
              className="flex flex-col min-w-[360px] sm:min-w-[520px] max-w-[540px] py-5 px-6 gap-y-6 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]"
            >
              <header className="flex flex-1 items-center justify-between gap-x-8">
                <div className="flex items-center gap-x-2">
                  <div className="w-7 h-7">
                    <HiveKeychainKeys />
                  </div>
                  <h1 className="text-2xl font-bold">Login with Keychain</h1>
                </div>

                <button
                  type="button"
                  className="flex items-center justify-center w-8 h-8 rounded-full border border-pri/50 dark:border-pri-d/50 text-pri/80 dark:text-pri-d/80 hover:border-pri dark:hover-border-pri-d hover:text-pri dark:hover:text-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
                  onClick={() => setVisibility(false)}
                >
                  <FontAwesomeIcon icon={faX} size="xs" />
                </button>
              </header>

              {/* <div>
                <label htmlFor="keystore-file" className="flex flex-col gap-y-2">
                  <span className="text-white/90 text-sm font-medium">Keystore File</span>
                  <span
                    className={cn(
                      "flex flex-col justify-center items-center w-full h-48 gap-y-6 rounded-xl border-2 border-dashed border-pri-d/70 bg-black transition-all duration-150 text-[#86868b] cursor-pointer",
                      {
                        "hover:bg-[#101010]": !keystoreProps.keystore,
                        "border-solid border-green-500 bg-green-500/10 text-green-500": keystore
                      }
                    )}
                  >
                    <FontAwesomeIcon icon={keystoreProps.keystore ? faCheckCircle : faFileUpload} size="2xl" />
                    <span className="text-sm font-medium">
                      {keystoreProps.keystore ? (
                        <UploadedFilePreview file={keystoreProps.keystore} removeFile={removeKeystore} />
                      ) : (
                        "Click to select your keystore file"
                      )}
                    </span>
                  </span>
                </label>

                <input
                  id="keystore-file"
                  type="file"
                  multiple={false}
                  onChange={handleChange}
                  className="hidden"
                  hidden
                />
              </div>

              <div>
                <label className="block text-white/90 mb-2 text-sm font-medium">Encryption Password</label>

                <input
                  type="password"
                  value={keystoreProps.keystorePass}
                  onChange={ev => keystoreProps.setKeystorePass(ev.target.value)}
                  className="w-full py-3 px-3 pr-10 rounded-xl bg-[#101010] border border-pri-d/50 text-sm font-medium outline-2 outline-offset-1 outline-transparent focus:outline-pri placeholder:text-pri-d/40 transition-all duration-150"
                  placeholder="•••••••••"
                />
              </div> */}

              <form
                onSubmit={event => {
                  event.preventDefault();
                  onSubmit();
                }}
                className="flex flex-1 flex-col gap-y-8"
              >
                <div className="flex flex-col gap-y-1">
                  <label htmlFor="keychain-username" className="font-medium text-sm text-pri dark:text-pri-d pl-px">
                    Username
                  </label>
                  <input
                    id="keychain-username"
                    type="text"
                    className="w-full py-3 px-3 pr-10 rounded-xl bg-[#101010] border border-pri-d/50 text-sm font-medium outline-2 outline-offset-1 outline-transparent focus:outline-pri placeholder:text-pri-d/40 transition-all duration-150"
                    placeholder="Enter your username"
                    value={username}
                    onChange={e => setUsername(e.target.value?.toLowerCase())}
                    autoFocus
                  />
                </div>

                <button
                  type="submit"
                  title="Sign in with Keychain"
                  aria-label="Sign in with Keychain"
                  className="flex justify-center items-center min-w-[138px] sm:min-w-[185px] h-[30px] sm:h-[40px] py-2.5 px-6 mx-auto rounded-full text-sm font-medium bg-pri-d dark:bg-pri text-pri-d dark:text-pri hover:opacity-90 transition-opacity duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={loading}
                >
                  {loading ? <FontAwesomeIcon icon={faSpinner} className="animate-spin" /> : "Sign in with Keychain"}
                </button>
              </form>
            </div>
          </m.div>
        )}
      </LazyMotion>
    </FloatingPortal>
  );
}

